define("apollo/pods/components/planning/custom-fields-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QInEzulw",
    "block": "{\"symbols\":[\"pair\"],\"statements\":[[4,\"each\",[[23,[\"customFields\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"form-group small-margin-form-group row\"],[9],[0,\"\\n            \"],[7,\"label\"],[11,\"class\",\"col-md-5 col-xs-12 control-label\"],[9],[0,\"\\n                \"],[1,[22,1,[\"definition\",\"nameInAppropriateLanguage\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[22,1,[\"value\",\"changeMessage\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"span\"],[11,\"class\",\"alert-text\"],[9],[0,\"\\n                        \"],[7,\"i\"],[11,\"class\",\"fa fa-exclamation-circle\"],[12,\"title\",[28,[[22,1,[\"value\",\"changeMessage\"]]]]],[9],[10],[0,\"\\n                    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"            \"],[10],[0,\"\\n\\n            \"],[7,\"div\"],[12,\"class\",[28,[\"col-md-7 col-xs-12 \",[27,\"if\",[[22,1,[\"value\",\"errors\",\"option\"]],\"has-error\"],null]]]],[11,\"data-field-type\",\"custom-field\"],[12,\"data-field-name\",[28,[[22,1,[\"definition\",\"englishNameTrimmed\"]]]]],[9],[0,\"\\n                \"],[1,[27,\"planning/custom-fields-section/custom-field\",null,[[\"pair\",\"customFields\",\"placeholder\"],[[22,1,[]],[23,[\"customFields\"]],[27,\"t\",[\"common.selectValue\"],null]]]],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/planning/custom-fields-section/template.hbs"
    }
  });

  _exports.default = _default;
});