define("apollo/utils/validators/step-dates-validator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function arrivalDateBlank(step, transportType, value) {
    const requiresArrivalAndDepartureDateInWarehouseStep = transportType.get('requiresArrivalAndDepartureDateInWarehouseStep');
    const requiresArrivalAndDepartureDateInCarrierStep = transportType.get('requiresArrivalAndDepartureDate');
    const stepInWarehouse = step.get('belongsToWarehouse'); // Walidacja obowiązuje tylko gdy w formularzu sa widoczne stepy

    if (!transportType.get('hasVisibleSteps')) {
      return true;
    } // Typ transportu posiada dwa ustawienia na obligatoryjność zakresu w stepie,
    // jedno odnośi się do stepu magazynowego (requiresArrivalAndDepartureDateInWarehouseStep),
    // a drugie do stepu kontrahenta(requiresArrivalAndDepartureDate).
    // Walidacja na pole ArrivalDate nie obowiązuje, jeśli
    // flaga requiresArrivalAndDepartureDate = true
    // i step nie jest magazynowy


    if (requiresArrivalAndDepartureDateInCarrierStep && !stepInWarehouse) {
      return true;
    } // Walidacja na pole ArrivalDate nie obowiązuje jeśli
    // flaga requiresArrivalAndDepartureDateInWarehouseStep = true
    // i jest magazynowy


    if (requiresArrivalAndDepartureDateInWarehouseStep && stepInWarehouse) {
      return true;
    } // pole jest wymagane tylko dla stepów które nie mają magazynu, podczas tworzenia transpotru mówi o tym zmienna ownedByPrincipal


    return !(!stepInWarehouse && !value);
  }

  function arrivalDateInPast(step, transportType, value) {
    // validacja obowiązuje tylko gdy w formularzu sa widoczne stepy
    if (!transportType.get('hasVisibleSteps')) {
      return true;
    } // walidacja obowiązuje tylko wtedy, gdy pryncypał ma ją ustawioną


    if (step.get('sessionAccount').getSettingValue('CAN_ARRIVAL_DATE_BE_IN_THE_PAST') === 'true') {
      return true;
    } // pole jest wymagane tylko dla stepów które nie mają magazynu, podczas tworzenia transpotru mówi o tym zmienna ownedByPrincipal


    if (!step.get('belongsToWarehouse') && _getChangedAttributes(step).arrivalDate !== undefined) {
      return value >= new Date();
    }

    return true;
  }

  function minAdviceDateBlank(step, transportType, value) {
    if (!transportType.get('requiresAdviceDateRestrictions') || !step.get('isInWarehouse')) {
      return true;
    }

    return !!value;
  }

  function minAdviceDateAfterWindowStart(step, value) {
    if (!value || !step.get('timeWindow.id')) {
      return true;
    }

    return value <= step.get('timeWindow.start');
  }

  function minAdviceDateOutsideOfInterval(step, value) {
    if (!value || !step.get('warehouse.id')) {
      return true;
    }

    if (step.get('transport.isRelatedTransportInTransportCombining')) {
      return true;
    }

    const windowInterval = step.get('warehouse.timeWindowOffsetInterval');
    const selectedMinutes = parseInt(moment(value).format('mm'));
    return selectedMinutes % windowInterval === 0;
  }

  function minAdviceDateWorkingHours(step, value) {
    if (!value || !step.get('warehouse.id')) {
      return true;
    }

    const workStartHour = step.get('warehouse.workStartHour');
    const workEndHour = step.get('warehouse.workEndHour');
    const workStartOnMaxDateDay = moment(value).startOf('day').add(workStartHour, 'hours').toDate();
    const workEndOnMaxDateDay = moment(value).startOf('day').add(workEndHour, 'hours').toDate();
    const dateValue = new Date(value);
    return dateValue >= workStartOnMaxDateDay && dateValue <= workEndOnMaxDateDay;
  }

  function maxAdviceDateBlank(step, transportType, value) {
    if (!transportType.get('requiresAdviceDateRestrictions') || !step.get('isInWarehouse')) {
      return true;
    }

    return !!value;
  }

  function maxAdviceDateEarly(step, value) {
    if (!value || !step.get('minAdviceDate')) {
      return true;
    }

    return new Date(value) >= new Date(step.get('minAdviceDate'));
  }

  function maxAdviceDateInPast(step, transportType, value) {
    // Wymóg podania daty "do" w przyszłości powinien obowiązywać tylko
    // do czasu wybrania okna czasowego - później walidacja zakresu będzie
    // związana z wybranym oknem.
    if (!value || step.get('timeWindow.id')) {
      return true;
    }

    if (step.get('isLoad') && _getChangedAttributes(step).maxAdviceDate !== undefined) {
      const oldMaxAdviceDateValue = moment(_getChangedAttributes(step).maxAdviceDate.get(0)).format('DD/MM/YYYY HH:mm');
      const newMaxAdviceDateValue = moment(_getChangedAttributes(step).maxAdviceDate.get(1)).format('DD/MM/YYYY HH:mm');

      if (oldMaxAdviceDateValue === newMaxAdviceDateValue) {
        return true;
      }
    }

    if (_getChangedAttributes(step).maxAdviceDate === undefined) {
      return true;
    }

    return new Date(value) > new Date();
  }

  function maxAdviceDateWorkHours(step, value) {
    if (!value || !step.get('minAdviceDate') || !step.get('warehouse.id')) {
      return true;
    }

    const workStartHour = step.get('warehouse.workStartHour');
    const workEndHour = step.get('warehouse.workEndHour');
    const validatedValue = moment(value).add(-1, 'seconds');
    const workStartOnMaxDateDay = moment(validatedValue).startOf('day').add(workStartHour, 'hours').toDate();
    const workEndOnMaxDateDay = moment(validatedValue).startOf('day').add(workEndHour, 'hours').toDate();
    return validatedValue >= workStartOnMaxDateDay && validatedValue <= workEndOnMaxDateDay;
  }

  function maxAdviceDateBeforeWindowStep(step, value) {
    if (!value || !step.get('timeWindow.id')) {
      return true;
    }

    return value >= step.get('timeWindow.stop');
  }

  function maxAdviceDateOutsideInterval(step, value) {
    if (!value || !step.get('warehouse.id')) {
      return true;
    }

    if (step.get('transport.isRelatedTransportInTransportCombining')) {
      return true;
    }

    const windowInterval = step.get('warehouse.timeWindowOffsetInterval');
    const selectedMinutes = parseInt(moment(value).format('mm'));
    return selectedMinutes % windowInterval === 0;
  }

  function _getChangedAttributes(step) {
    let changedAttributes;

    try {
      changedAttributes = step.content.changedAttributes();
    } catch (e) {
      changedAttributes = step.changedAttributes();
    }

    return changedAttributes;
  }

  var _default = {
    arrivalDateBlank,
    arrivalDateInPast,
    minAdviceDateBlank,
    minAdviceDateOutsideOfInterval,
    minAdviceDateAfterWindowStart,
    minAdviceDateWorkingHours,
    maxAdviceDateBlank,
    maxAdviceDateOutsideInterval,
    maxAdviceDateInPast,
    maxAdviceDateEarly,
    maxAdviceDateWorkHours,
    maxAdviceDateBeforeWindowStep
  };
  _exports.default = _default;
});