define("apollo/pods/delivery-packaging/model", ["exports", "ember-data", "apollo/pods/packaging/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _model.BasePackagingModel.extend({
    rounding: Ember.inject.service('rounding-service'),
    stackingFactor: attr('number'),
    ldm: attr('number'),
    packagingLdm: Ember.computed('ldm', function () {
      return this.rounding.roundToTwoDecimals(this.get('ldm'));
    }),
    validations: { ..._model.BasePackagingModelValidations,
      stackingFactor: {
        custom: [{
          validation: function (key, value) {
            return !!value;
          },
          message: 'blankSelect'
        }]
      }
    }
  });

  _exports.default = _default;
});