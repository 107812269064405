define("apollo/pods/components/remove-relation-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Zfu2Ts9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"canRemoveTransportRelation\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn btn-danger\"],[11,\"data-field-name\",\"transport-remove-relation\"],[11,\"type\",\"button\"],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"transport.relations.removeRelation\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"onButtonClick\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"showRemoveRelationModal\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"modals/remove-transport-relation\",null,[[\"relatedTo\",\"onDelete\",\"onClose\"],[[23,[\"transport\",\"relatedTo\"]],[27,\"action\",[[22,0,[]],\"removeRelationHandler\"],null],[27,\"action\",[[22,0,[]],\"toggleShowRemoveRelationModal\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/remove-relation-button/template.hbs"
    }
  });

  _exports.default = _default;
});