define("apollo/pods/components/transport/couriers-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    data: null,
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    enums: Ember.inject.service('enum-service'),
    goodsValueCurrency: Ember.A(['EUR', 'PLN', 'USD', 'CHF', 'CZK']),
    isLoading: null,
    sessionAccount: Ember.inject.service('session-account'),

    init() {
      this._super(...arguments);

      this.isLoading = true;
      this.data = [];
    },

    incoterms: Ember.computed('', function () {
      return this.get('enums').get('incoterms');
    }),
    getCurrencies: Ember.computed('sessionAccount.currentUser', function () {
      return this.get('store').peekAll('currency').map(c => c.get('symbol'));
    }),
    couriersSectionErrors: Ember.computed('transport.errors.[]', function () {
      return [this.get('transport.errors.goodsValue'), this.get('transport.errors.goodsValueCurrency'), this.get('transport.errors.incoterms')];
    }),
    disableEditionOfCouriersFields: Ember.computed('', function () {
      return !this.get('sessionAccount').hasRole('ROLE_CAN_EDIT_COURIERS_SECTION');
    }),
    sortFunction: function (a, b) {
      // puste ceny, czyli błedy idą na sam dól
      if (a.price.value === '') {
        return 1;
      }

      if (b.price.value === '') {
        return -1;
      } // pozostałe sortujemy po cenie, od najniższej do najwyższej


      return a.price.value < b.price.value ? -1 : 1;
    },
    actions: {
      fetchData() {
        const transportExists = this.get('transport.id') !== null;

        if (!transportExists) {
          return;
        }

        this.get('transport').reload().then(transport => {
          const pricings = transport.get('courierPricings');

          if (pricings.length === 0 && !pricings.isFulfilled) {
            const self = this;
            const retryFetchDataTimeout = 10000;
            setTimeout(function () {
              self.send('fetchData');
            }, retryFetchDataTimeout);
            return;
          }

          this.set('data', []);
          pricings.forEach(pricing => {
            const pricingSuccesses = pricing.get('pricingSuccesses');
            const pricingErrors = pricing.get('pricingErrors');

            if (pricingSuccesses.length > 0) {
              pricingSuccesses.forEach(success => {
                this.data.push({
                  'courier': success.get('courierPricing.courierCompany'),
                  'price': {
                    'value': success.get('courierPricing.courierCompany.name') === 'DHL Express' && success.price ? success.price : success.priceSumNetto != null ? success.priceSumNetto : success.price,
                    'currency': success.priceCurrency
                  },
                  'price_brutto': success.price,
                  'price_fuel': success.priceFuel,
                  'price_sum_netto': success.priceSumNetto,
                  'price_sum_vat': success.priceSumVat,
                  'price_es': success.dhlPriceEs,
                  'price_base': success.priceBase,
                  'price_base_vat': success.priceBaseVat,
                  'price_peak': success.pricePeak,
                  'price_multiple_packaging': success.priceMultiplePackaging,
                  'price_dhl_premium': success.dhlPricePremium,
                  'type_of_service': success.serviceName,
                  'delivery_date': success.dhlDeliveryCapabilities ? moment(success.dhlDeliveryCapabilities).format('YYYY-MM-DD hh:ss') : '',
                  'order_by': success.dhlDeliveryCapabilities ? moment(success.dhlPickupCapabilities).format('YYYY-MM-DD hh:ss') : ''
                });
              });
            } else {
              const msg = this.get('intl').t('common.error');
              pricingErrors.forEach(error => {
                this.data.push({
                  'courier': error.get('courierPricing.courierCompany'),
                  'price': {
                    'value': '',
                    'currency': ''
                  },
                  'type_of_service': msg + ': ' + error.errorCode + ' | ' + error.errorMessage,
                  'delivery_date': '',
                  'order_by': ''
                });
              });
            }
          });
          this.data.sort(this.sortFunction);
        });
        this.set('isLoading', false);
        return this.data;
      }

    }
  });

  _exports.default = _default;
});