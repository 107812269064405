define("apollo/pods/price-list/modals/rate-form/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    isExistingRouteOffer: Ember.computed('model.routeOffer', function () {
      return this.get('routeOffer.dateCreated') !== undefined && this.get('creator') !== null;
    }),
    route: Ember.computed('model.route', function () {
      return this.get('model.route');
    }),
    routeOffer: Ember.computed('model.routeOffer', function () {
      return this.get('model.routeOffer');
    }),
    truckTypes: Ember.computed('model.truckTypes', function () {
      return this.get('model.truckTypes');
    }),
    actions: {
      setContractorCompanyData(item) {
        const self = this;
        this.get('store').findRecord('company', item.id).then(company => {
          self.get('routeOffer').set('company', company);
        });
      },

      removeContractorCompany() {
        this.get('routeOffer').set('company', null);
      },

      setValidityDateStart(dateValues) {
        this.get('routeOffer').set('validityDateStart', dateValues[0]);
      },

      setValidityDateEnd(dateValues) {
        this.get('routeOffer').set('validityDateEnd', dateValues[0]);
      },

      saveNewRate() {
        const self = this;
        this.set('routeOffer.creator', this.get('sessionAccount.currentUser'));
        this.set('routeOffer.route', this.get('route'));

        if (!this.get('routeOffer').validate()) {
          return;
        }

        this.get('routeOffer').save().then(savedRouteOffer => {
          console.debug(`Zapisaliśmy stawkę ${savedRouteOffer.toJSON()}`);
          self.send('hideModal');
        }).catch(response => {
          self.setProperties({
            errors: response.errors
          });
          self.get('routeOffer').rollbackAttributes();
        });
      },

      update() {
        const routeOffer = this.get('routeOffer');

        if (!routeOffer.validate()) {
          return;
        }

        const self = this;
        routeOffer.save().then(updatedRouteOffer => {
          console.debug(`Zaktualizowaliśmy stawkę ${updatedRouteOffer.toJSON()}`);
          self.send('hideModal');
        }).catch(response => {
          self.setProperties({
            errors: response.errors
          });
          self.get('routeOffer').rollbackAttributes();
        });
      },

      cancel() {
        this.send('close');
      },

      close() {
        this.get('routeOffer').rollbackAttributes();
        this.set('errors', null);
        this.send('hideModal');
      }

    }
  });

  _exports.default = _default;
});