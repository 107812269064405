define("apollo/services/rounding-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TWO_DECIMAL_PLACES = 2;

  var _default = Ember.Service.extend({
    roundToDecimals(value, decimalPlaces) {
      if (value === null || value === undefined) {
        return '';
      }

      return parseFloat(value).toFixed(decimalPlaces);
    },

    roundToTwoDecimals(value) {
      return this.roundToDecimals(value, TWO_DECIMAL_PLACES);
    }

  });

  _exports.default = _default;
});