define("apollo/pods/components/transport/transport-packages-section/modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    availableStackingFactors: Ember.A([1, 2, 3, 4]),
    packageTypes: Ember.computed(function () {
      return this.store.peekAll('packageType');
    }),
    actions: {
      onSaveHandler: function (transportPackage) {
        if (!transportPackage.validate()) {
          return;
        }

        if (this.get('isNewTransport')) {
          this.onSave();
          return;
        }

        transportPackage.save().then(() => {
          this.onSave();
        });
      },
      onCloseHandler: function () {
        this.onClose();
      }
    }
  });

  _exports.default = _default;
});