define("apollo/pods/components/planning/delivery-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    enums: Ember.inject.service('enum-service'),
    incoterms: Ember.computed('', function () {
      return this.get('enums').get('incoterms');
    }),
    disableFiltering: Ember.computed('filters', function () {
      return !this.get('filters');
    }),
    columns: Ember.computed(function () {
      const columns = [{
        propertyName: 'deliveryNumber',
        title: this.get('intl').t('delivery.deliveryNumber'),
        className: 'column-delivery-number',
        disableFiltering: this.get('disableFiltering'),
        disableSorting: true
      }, {
        propertyName: 'unloadAddressRecipient',
        title: this.get('intl').t('common.client'),
        className: 'column-delivery-client',
        disableFiltering: this.get('disableFiltering'),
        disableSorting: true
      }, {
        propertyName: 'loadStepPostalAndCity',
        title: this.get('intl').t('delivery.step.load'),
        className: 'column-delivery-load',
        disableFiltering: this.get('disableFiltering'),
        disableSorting: true
      }, {
        propertyName: 'unloadStepPostalAndCity',
        title: this.get('intl').t('delivery.step.unload'),
        className: 'column-delivery-unload',
        disableFiltering: this.get('disableFiltering'),
        disableSorting: true
      }];

      if (this.get('disableFiltering')) {
        columns.push({
          propertyName: 'unloadArrivalOrMinAdviceDate',
          title: this.get('intl').t('common.plannedDate'),
          className: 'column-delivery-plannedDate',
          disableFiltering: true,
          disableSorting: true
        });
      } else {
        columns.push({
          propertyName: 'unloadArrivalOrMinAdviceDate',
          title: this.get('intl').t('common.plannedDate'),
          className: 'column-delivery-plannedDate',
          componentForFilterCell: 'server-table/filters/date-time-filter'
        });
      }

      columns.push({
        propertyName: 'incoterms',
        title: this.get('intl').t('delivery.incoterms'),
        className: 'column-delivery-incoterms',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('incoterms'),
        disableFiltering: this.get('disableFiltering'),
        disableSorting: true
      }, {
        propertyName: 'grossWeight',
        title: this.get('intl').t('common.grossWeightKg'),
        className: 'column-delivery-grossWeight',
        disableFiltering: this.get('disableFiltering'),
        disableSorting: true
      });
      return columns;
    })
  });

  _exports.default = _default;
});