define("apollo/pods/components/remove-relation-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    ajaxService: Ember.inject.service('ajax-service'),
    showRemoveRelationModal: false,
    canRemoveTransportRelation: Ember.computed('transport.{masterTransports.length,relatedToTransports.length}', function () {
      const canRemove = this.get('transport').actionCanBePerformed('ROLE_CAN_DELETE_RELATION');
      const isMaster = this.get('transport.relatedToTransports.length') > 0;
      const isRelatedTo = this.get('transport.masterTransports.length') > 0;
      return canRemove && (isRelatedTo || isMaster);
    }),
    actions: {
      toggleShowRemoveRelationModal: function () {
        this.toggleProperty('showRemoveRelationModal');
      },
      onButtonClick: function () {
        const transport = this.get('transport');
        const relations = transport.get('relatedTo');

        if (relations.get('length') > 1) {
          this.toggleProperty('showRemoveRelationModal');
          return;
        }

        this.send('removeRelationHandler', relations.get('firstObject'));
      },
      removeRelationHandler: function (relationToRemove) {
        const self = this;
        const transport = this.get('transport');
        const masterTransport = transport.get('masterTransports.firstObject');
        this.get('ajaxService').send(`/transport-transport-relations/${relationToRemove.id}`, 'DELETE').then(() => {
          if (masterTransport) {
            masterTransport.get('transportType').set('transportsTabVisited', false);
            masterTransport.reload();
            self.refreshRelatedHasToWaitTransports(masterTransport);
          }

          const combinedTransports = transport.get('allCombinedTransports');
          combinedTransports.forEach(t => {
            t.reload();
          });
          self.get('transport.transportType').set('transportsTabVisited', false);
          self.get('transport').reload();
          self.send('refreshRoute');
          self.send('hideModal');
        }).catch(response => {
          $('.transport-modal').scrollTop(0);
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});