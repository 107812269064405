define("apollo/services/transport-packaging-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    delete(transportPackaging) {
      try {
        return transportPackaging.destroyRecord().then(() => {
          this.store.unloadRecord(transportPackaging);
          return true;
        });
      } catch (e) {
        console.error(e);
        return false;
      }
    }

  });

  _exports.default = _default;
});