define("apollo/pods/components/packages-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * PackagesTable - A powerful, customizable table component with advanced features
   *
   * @typedef {Object} ColumnDefinition
   * @property {string} key - Unique identifier for the column
   * @property {string} label - Display label for the column header
   * @property {boolean} [sortable=false] - Enable/disable sorting for this column
   * @property {boolean} [summable=false] - Enable sum calculation for numeric columns
   * @property {string} [tdClass] - Custom CSS class for the column
   * @property {Function} [template] - Custom template for column rendering
   *
   * @typedef {Object} PackagesTableArgs
   * @property {ColumnDefinition[]} columns - Columns configuration
   * @property {Object[]} rows - Data rows to be displayed
   * @property {string} [noDataMessage="No data available"] - Message when no rows are present
   * @property {boolean} [striped=false] - Apply zebra-striping to table rows
   * @property {boolean} [hover=false] - Add hover effect to table rows
   * @property {boolean} [showSummary=false] - Display a summary row for summable columns
   * @property {string} [defaultSortKey=null] - Sets a defaultSortKey if specified
   * @property {string} [tableClass] - Additional CSS classes for the table
   * @property {boolean} [actionColumn=false] - Enable an action column for row-level actions
   * @property {Function} [onSort] - Callback for custom sorting logic
   *
   * @class PackagesTable
   * @description A versatile table component with advanced features like sorting, summary calculations, and flexible column configurations
   *
   * @example
   * // Basic usage
   * {{packages-table
   *   columns=(array
   *      (hash key="test1" label="Label1" sortable=true)
   *      (hash key="test2" label="Label2" sortable=true template=(component "packages-table/templates/status-badge"))
   *      (hash key="test3" label="Label3" summable=true sumFunction: (row, columns) => {return row['price'] * row['quantity']})
   *   )
   *   rows=(array
   *      (hash test1=1 test2=(hash status="completed"))
   *      (hash test1=2 test2=(hash status="pending"))
   *   )
   *   noDataMessage="No packages found"
   *   striped=true
   *   hover=true
   *   onSort=(action "handleSort")
   * }}
   *
   * @example
   * // With action column
   * {{#packages-table
   *   columns=
   *   rows=
   *   actionColumn=true
   *   as |package|
   * }}
   *   <button {{on "click" (action "editPackage" package)}}>Edit</button>
   *   <button {{on "click" (action "deletePackage" package)}}>Delete</button>
   * {{/packages-table}}
   */
  var _default = Ember.Component.extend({
    _columns: Ember.computed('columns.[]', function () {
      return this.columns || Ember.A([]);
    }),
    _rows: Ember.computed('rows.[]', function () {
      return this.rows || Ember.A([]);
    }),
    sortKey: Ember.computed({
      get(key) {
        return this.defaultSortKey || null;
      },

      set(key, value) {
        return value;
      }

    }),
    sortDirection: Ember.computed({
      get(key) {
        return 'asc';
      },

      set(key, value) {
        return value;
      }

    }),
    sortedRows: Ember.computed('_rows', 'sortKey', 'sortDirection', function () {
      if (!this.sortKey) {
        return this._rows;
      }

      return this._rows.toSorted((a, b) => {
        if (!a || !b) return -1;
        const valA = a[this.sortKey];
        const valB = b[this.sortKey];
        if (valA === valB) return 0;
        const comparison = valA > valB ? 1 : -1;
        return this.sortDirection === 'asc' ? comparison : -comparison;
      });
    }),
    columnSums: Ember.computed('_rows', function () {
      const calculateColumnSum = (column, rows) => {
        // If a custom sum function is provided, use it
        if (column.sumFunction) {
          return rows.reduce((total, row) => total + column.sumFunction(row, this._columns), 0);
        } // Default numeric summing


        return rows.reduce((total, row) => {
          const value = parseFloat(row[column.key]);
          return isNaN(value) ? total : total + value;
        }, 0);
      }; // Only process columns marked as summable


      return this._columns.filter(column => column.summable).reduce((sums, column) => {
        sums[column.key] = calculateColumnSum(column, this.sortedRows);
        return sums;
      }, {});
    }),
    actions: {
      /**
       * Handles sorting when a column header is clicked
       * @param {Object} column - The column to sort by
       * @param {string} column.key - Unique key for the column
       * @param {boolean} column.sortable - Whether the column is sortable
       */
      sortBy(column) {
        if (!column.sortable) return;

        if (this.sortKey === column.key) {
          this.set('sortDirection', this.sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
          this.set('sortKey', column.key);
          this.set('sortDirection', 'asc');
        } // TODO
        // Custom sort or default implementation
        // if (this.onSort) {
        //     this.onSort(this.sortKey, this.sortDirection)
        // }

      }

    }
  });

  _exports.default = _default;
});