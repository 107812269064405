define("apollo/pods/users/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: Ember.A(['ROLE_EDIT_USERS_MAIN_DATA']),
    model: function (params, transition) {
      const currentUser = this.get('sessionAccount').get('currentUser');
      const hasAnyOfTheRoles = this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'));
      const userRoleGroup = this.get('sessionAccount').get('currentUser.roleGroup');
      const roleGroupInvRestriction = this.store.query('role-group-invitation-restriction', {
        roleGroup: userRoleGroup.get('id')
      });
      const self = this;

      if (params.user_id === currentUser.get('id') || hasAnyOfTheRoles) {
        return new Promise(function (resolve) {
          self.store.findRecord('user', params.user_id).then(response => {
            resolve(Ember.RSVP.hash({
              user: response,
              roleGroupInvRestriction
            }));
          }, function () {
            return self.transitionTo('forbidden');
          });
        });
      } else {
        transition.abort();
        this.transitionTo('users.ramps', params.user_id);
      }
    },

    afterModel(model) {
      model.user.get('company').then(c => {
        if (c.get('isAuxiliaryPartner')) {
          console.log(`Company ${c.get('shortName')} is a partner of one of contractors. Edition is disabled -> redirecting to user's details view.`);
          this.transitionTo('users.show', model.get('user.id'));
        }
      });
    },

    renderTemplate: function () {
      this.render('users.create');
    },
    setupController: function (controller, model) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.controllerFor('users.create').setProperties({
        model
      });
    },
    actions: {
      willTransition() {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        this.controllerFor('users.create').setProperties({
          'successMessage': null,
          'model.user.changePassword': false,
          'model.user.password': '',
          'model.user.newPassword': '',
          'model.user.repeatedNewPassword': ''
        });
        return true;
      }

    }
  });

  _exports.default = _default;
});