define("apollo/pods/components/trucks/truck-type-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    async init() {
      this._super(...arguments);
    },

    showWarning: Ember.computed('model.showLdmWarning', function () {
      return this.get('model.showLdmWarning');
    }),
    _truckTypes: Ember.computed('model.truckTypes', function () {
      return this.get('truckTypes') ? this.get('truckTypes') : this.get('model.truckTypes');
    }),
    transportType: Ember.computed('model.transportType', function () {
      return this.get('model.transportType');
    })
  });

  _exports.default = _default;
});