define("apollo/pods/components/show-delivery-invoice-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    async init() {
      this._super(...arguments);

      await this.get('delivery.invoiceNumber');
      await this.get('delivery.freightCost');
      await this.get('delivery.freightCostCurency.symbol');
    },

    invoiceNumber: Ember.computed('delivery.invoiceNumber', function () {
      return this.get('delivery.invoiceNumber');
    }),
    price: Ember.computed('delivery.{freightCost,freightCostCurrency.symbol}', function () {
      return this.get('delivery.freightCost') + ' ' + this.get('delivery.freightCostCurrency.symbol');
    })
  });

  _exports.default = _default;
});