define("apollo/pods/components/transport/delivery-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    modalService: Ember.inject.service('modal-service'),
    deliveryService: Ember.inject.service('delivery-service'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    tagName: '',
    errors: Ember.A(),
    detachErrors: Ember.A(),

    async init() {
      this._super(...arguments);

      this.set('loading', true);
      await this.get('delivery.loadStep');
      await this.get('delivery.unloadStep');
      await this.get('delivery.loadStep.address');
      await this.get('delivery.unloadStep.address');
      await this.get('delivery.loadStep.warehouse');
      await this.get('delivery.loadStep.warehouse.address');
      await this.get('delivery.unloadStep.warehouse');
      await this.get('delivery.unloadStep.warehouse.address');
      await this.get('delivery.freightCostCurrency');
      await this.get('delivery.deliveryCustomFieldValues');
      await this.get('delivery.transportType');
      await this.get('delivery.packaging');
      await this.get('delivery.packaging.packageType');
      await this.get('delivery.deliverySetting');
      await this.get('transport'); // Edycja jest możliwa tylko jeśli delivery już istnieje, nie jest z msp i user ma odpowiednią rolę

      const hasRole = this.get('sessionAccount').hasRole('ROLE_CAN_EDIT_DELIVERY');

      if (this.get('delivery.id') && (!hasRole || this.get('delivery.isFromSap'))) {
        this.set('disabled', true);
      }

      this.set('loading', false);
    },

    detachErrorText: Ember.computed('detachErrors', function () {
      if (this.get('detachErrors.length') > 0) {
        return this.get('detachErrors').toString();
      }

      return null;
    }),
    canDetachDelivery: Ember.computed('transport.[]', function () {
      if (!this.get('transport.id')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('ROLE_DETACH_DELIVERY');
    }),
    delivery: Ember.computed('model.delivery', function () {
      return this.get('model.delivery');
    }),
    shouldShowButtons: Ember.computed('disabled', 'model.delivery', function () {
      const disabled = !!this.get('disabled');
      const isInTransport = this.get('transport.id');

      if (isInTransport) {
        return false;
      }

      return !disabled;
    }),
    canDeleteDelivery: Ember.computed('delivery.id', function () {
      const role = this.get('sessionAccount').hasRole('ROLE_CAN_DELETE_DELIVERY');
      const deliveryId = !!this.get('delivery.id');
      return role && deliveryId;
    }),
    actions: {
      handleDeliveryCustomFields(delivery) {
        this.deliveryService.handleDeliveryCustomFields(delivery, this.get('customFieldsList'));
      },

      saveDelivery() {
        const self = this;
        const delivery = this.get('delivery');
        const isNew = !this.get('delivery.id');

        if (!delivery.validate()) {
          console.error('Walidacja Delivery zwróciła błąd/bledy');
          delivery.errors.forEach(error => console.error(error.attribute + ' ' + error.message));
          return;
        }

        if (!delivery.get('id')) {
          this.send('handleDeliveryCustomFields', delivery);
        }

        delivery.save().then(delivery => {
          delivery.reload();
          self.get('deliveryService').handleUpdatedDeliveryCustomFields(delivery);
          self.get('deliveryService').handleUpdatedDeliveryPackaging(delivery);
          self.get('modalService').hideModal();
          self.get('modalService').refreshModel();

          if (isNew) {
            self.deliveryService.showTransportCreatedToast(delivery);
          }
        }).catch(e => {
          self.set('errors', e.errors);
          console.error(`Błąd podczas zapisywania delivery: ${e}`);
        });
      },

      deleteDelivery(delivery) {
        delivery.deleteRecord();
        delivery.save();
        this.get('modalService').hideModal();
      },

      close() {
        this.get('delivery.packaging').forEach(pck => {
          if (pck.get('id')) {
            pck.rollbackAttributes();
          } else {
            pck.destroyRecord();
          }
        });

        if (this.get('delivery.id')) {
          this.get('delivery').rollbackAttributes();
        } else {
          this.get('delivery').destroyRecord();
        }

        this.get('modalService').hideModal();
      },

      async detachDelivery() {
        const transport = this.get('transport');

        if (!transport) {
          console.error('Nie możemy odpiąć delivery od transportu. Brak transportu powiązanego z delivery.');
          return;
        }

        const response = await this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.DELIVERY.DETACH_FROM_TRANSPORT, {
          id: this.get('delivery.id')
        }, null);

        if (response.ok) {
          this.set('detachErrors', Ember.A([]));
          transport.get('deliveries').removeObject(this.get('delivery'));
          const reloadedTransport = await transport.reload();

          if (reloadedTransport.get('archived')) {
            this.get('modalService').hideModal();
          }
        } else {
          const error = await response.json();
          this.set('detachErrors', Ember.A(error.errors));
        }
      }

    }
  });

  _exports.default = _default;
});