define("apollo/services/api/apollo-api-service", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Serwis odpowiedzialny za obsługę żądań API do serwera Apollo.
   */
  var _default = Ember.Service.extend({
    sessionAccount: Ember.inject.service('session-account'),
    BASE_URL: Ember.computed(function () {
      return _environment.default.serverURL;
    }),

    /**
     * Funkcja do wykonania żądania fetch do API Apollo.
     * @param {Object} apolloApiPath - Ścieżka do końcowego punktu API Apollo
     * @param {string} apolloApiPath.endpoint - Adres URL końcowego punktu
     * @param {string} apolloApiPath.method - Metoda HTTP
     * @param {string} apolloApiPath.description - Opis końcowego punktu API
     * @param {Object} params - Parametry żądania
     * @param {RequestInit} options - Dodatkowe opcje dla żądania fetch
     * @param {Object} searchParams - Parametry wyszukiwania
     * @example
     * fetch(APOLLO_API.WEIGHTS.EXAMPLE, null, {paramName1: 123456}, {queryParam: 'example'})
     * @returns {Promise<Response>} - Obietnica zawierająca odpowiedź
     */
    callApolloApi(apolloApiPath, params = null, options = null, searchParams = null) {
      const endpoint = apolloApiPath.endpoint;
      const method = apolloApiPath.method;
      const description = apolloApiPath.description;
      const token = this.get('sessionAccount').get('token') ? this.get('sessionAccount').get('token') : this.get('session.data.authenticated.token');
      const defaultOptions = {
        method,
        headers: {
          'Content-Type': 'application/json'
        }
      };

      if (token) {
        defaultOptions.headers.Authorization = `Bearer ${token}`;
      }

      const mergedOptions = { ...defaultOptions,
        ...options,
        headers: { ...defaultOptions.headers,
          ...(options ? options.headers : {})
        }
      };
      const url = this.BASE_URL + endpoint;

      const parsedUrl = this._handleFetchParams(url, params, searchParams);

      console.debug(`Fetching Apollo API on ${parsedUrl} :: ${description} :: ${JSON.stringify(mergedOptions)}`);
      return fetch(parsedUrl, mergedOptions);
    },

    /**
     * Funkcja do obsługi parametrów fetch takich jak parametry ścieżki i parametry wyszukiwania.
     * @param {string} url - Adres URL, do którego stosowane są parametry
     * @param {Object} params - Parametry ścieżki
     * @param {Object} searchParams - Parametry wyszukiwania
     */
    _handleFetchParams(url, params, searchParams) {
      if (params) {
        // Zamiana parametrów ścieżki w adresie URL np. :id => 123456
        url = Object.keys(params).reduce((acc, key) => {
          return acc.replace(`:${key}`, params[key]);
        }, url);
      }

      if (searchParams) {
        // Konstruowanie ciągu zapytania z parametrów wyszukiwania np. ?queryParam=test
        const query = Object.entries(searchParams).flatMap(([key, value]) => Array.isArray(value) ? value.map(v => [key, v]) : [[key, value]]).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join("&");
        url = query ? `${url}?${query}` : url;
      }

      return url;
    },

    /**
     * Stały obiekt zawierający definicje węzłów API Apollo.
     * Przykład nowego węzła:
     *  EXAMPLE: {
     *      NAME: {
     *          endpoint: '/example/:paramName1',
     *          method: 'GET',
     *          description: 'Example Apollo API endpoint'
     *      }
     *  },
     */
    APOLLO_API: Object.freeze({
      PERSON: {
        ACCOUNT_MAP: {
          endpoint: '/users/account/:username/:lang',
          method: 'GET',
          desciprtion: 'Downloads user map from backend'
        }
      },
      ANNOUNCEMENTS: {
        UPDATES: {
          endpoint: '/announcements/updates',
          method: 'GET',
          description: 'Polling for announcements'
        },
        MARK_AS_READ: {
          endpoint: '/announcements/:id/markAsRead',
          method: 'POST',
          description: 'Marks announcement with given id as read'
        }
      },
      CHECKPOINTS: {
        CONFIRM: {
          endpoint: '/checkpoints/:id/confirm',
          method: 'PUT',
          description: 'Confirms checkpoint with given :id'
        },
        REJECT: {
          endpoint: '/checkpoints/:id/reject',
          method: 'PUT',
          description: 'Rejects checkpoint with given :id'
        },
        BLOCK: {
          endpoint: '/checkpoints/:id/block',
          method: 'POST',
          description: 'Blocks checkpoint with given :id'
        },
        UNBLOCK: {
          endpoint: '/checkpoints/:id/unblock',
          method: 'POST',
          description: 'Unblocks checkpoint with given :id'
        },
        SAVE_MODAL: {
          endpoint: '/checkpoints/saveModal',
          method: 'POST',
          description: 'Unblocks checkpoint with given :id'
        }
      },
      CLASSIFICATION_RESOURCES: {
        ASSIGN_PLACE: {
          endpoint: '/classification-resources/assign-place',
          method: 'PUT',
          description: 'Assigns place for given classification resource'
        },
        RESERVE: {
          endpoint: '/classification-resources/reserve',
          method: 'POST',
          description: 'Reserves given classification resource'
        }
      },
      COMPANY_EMAIL_DOMAINS: {
        SAVE: {
          endpoint: '/company-email-domains',
          method: 'POST',
          description: 'Finds company email domains with given code'
        }
      },
      COMPANY_RAMPS: {
        SAVE: {
          endpoint: '/company-ramps',
          method: 'POST',
          description: 'Saves company-ramps object'
        }
      },
      FILES: {
        SAVE: {
          endpoint: '/files',
          method: 'POST',
          description: 'Saves file'
        }
      },
      FILE_SET: {
        DOWNLOAD: {
          endpoint: '/file-sets/download/:id',
          method: 'GET',
          description: 'Downloads file set with given id'
        }
      },
      GUS: {
        VERIFY: {
          endpoint: '/gus/verify',
          method: 'GET',
          description: 'Verifies company is GUS'
        }
      },
      INDEX_QUANTITIES: {
        SAVE: {
          endpoint: '/index-quantities',
          method: 'POST',
          description: 'Saves index quantity object'
        }
      },
      MANUAL_NOTIFICATIONS: {
        SEND: {
          endpoint: '/manual-notifications/send/:manualNotificationId',
          method: 'GET',
          description: 'Sends manual notification with given id'
        }
      },
      NET_PC: {
        GET_REPORT_URL: {
          endpoint: '/netpc/getReportUrl/:dashboardName',
          method: 'GET',
          description: 'Finds netpc report url with given dashboardName'
        }
      },
      NOTIFICATIONS: {
        // TODO: To jest coś do kolejek, możliwe, że już nie używane i można usunąć
        SEND_SMS: {
          endpoint: '/notifications/sms',
          method: 'PUT',
          description: 'Sends sms to all drivers in queue'
        }
      },
      PARTNERSHIPS: {
        CONNECT_ALL: {
          endpoint: '/partnerships/connectAll',
          method: 'POST',
          description: 'Creates partnerships with all contractor companies for principalCompany'
        }
      },
      PERSON_NOTIFICATIONS: {
        SAVE: {
          endpoint: '/person-notifications',
          method: 'POST',
          description: 'Saves person-notifications object'
        }
      },
      PERSON_RAMPS: {
        SAVE: {
          endpoint: '/person-ramps',
          method: 'POST',
          description: 'Saves person-ramps object'
        }
      },
      PERSON_QUEUES: {
        SAVE: {
          endpoint: '/person-queues',
          method: 'POST',
          description: 'Saves person-queue object'
        }
      },
      PLACES: {
        ENABLE: {
          endpoint: '/places/:placeId/enable',
          method: 'PUT',
          description: 'Enables place with given id'
        },
        DISABLE: {
          endpoint: '/places/:placeId/disable',
          method: 'PUT',
          description: 'Disables place with given id'
        }
      },
      ROLE_GROUP_AUTHORITIES: {
        SAVE_AGGREGATED_ROLE_GROUP_AUTHORITIES: {
          endpoint: '/role-group-authorities/aggregatedRoleGroupsAuthorities',
          method: 'POST',
          description: 'Saves aggregated role group authorities'
        }
      },
      ROLE_GROUP_STATISTICS_REPORTS: {
        SAVE: {
          endpoint: '/role-group-statistics-reports',
          method: 'POST',
          description: 'Saves role group statistics reports'
        }
      },
      ROLE_GROUP_NOTIFICATIONS: {
        SAVE: {
          endpoint: '/role-group-notifications',
          method: 'POST',
          description: 'Saves rnotification for role group'
        }
      },
      ROUTE_OFFERS: {
        CONFIRM: {
          endpoint: '/route-offers/:offerId/confirmation/',
          method: 'POST',
          description: 'Confirms route offer with given id'
        }
      },
      STEP_WAREHOUSE_LOCATION: {
        SAVE: {
          endpoint: '/steps/saveStepWarehouseLocation/',
          method: 'POST',
          description: 'Saves new step-warehouse-location'
        }
      },
      SETTINGS: {
        FIND_UNSET_NAMES: {
          endpoint: '/settings/findUnsetNames',
          method: 'GET',
          description: 'Finds settings that are unset'
        }
      },
      SUB_TASKS: {
        SAVE: {
          endpoint: '/subTasks/:taskId',
          method: 'POST',
          description: 'Saves sub tasks for given task'
        }
      },
      SQUARES: {
        ENABLE: {
          endpoint: '/squares/:squareId/enable',
          method: 'PUT',
          description: 'Enables square with given id'
        },
        DISABLE: {
          endpoint: '/squares/:squareId/disable',
          method: 'PUT',
          description: 'Disables square with given id'
        }
      },
      TRANSACTIONS: {
        EDIT_FREIGHT: {
          endpoint: '/transactions/:id/editFreight',
          method: 'PUT',
          description: 'Edits transaction freight with given transaction id'
        },
        SAVE_INVOICE: {
          endpoint: '/transactions/:id/saveInvoice',
          method: 'PUT',
          description: 'Saves transaction invoice data with given transaction id'
        }
      },
      TRANSPORTS: {
        GET: {
          endpoint: '/transports',
          method: 'GET',
          description: 'Get transports (API v2)'
        },
        INDEX: {
          endpoint: '/transports/index',
          method: 'POST',
          description: 'Used for polling for transports'
        },
        CREATE_MULTIPLE_COPIES: {
          endpoint: '/transports/createMultipleCopies',
          method: 'POST',
          description: 'Creates multiple copies for transport'
        },
        FILTER_OPTIONS: {
          endpoint: '/transports/filterOptions',
          method: 'POST',
          description: 'Finds all filter options for all select filters'
        },
        RESEND_API_MESSAGE: {
          endpoint: '/transports/resendApiMessage/:logId',
          method: 'POST',
          description: 'Resends api intergration message with given log id'
        },
        FIRST_POSSIBLE_START: {
          endpoint: '/transports/firstPossibleStart',
          method: 'POST',
          description: 'Finds first possible window start for given transport'
        },
        VALIDATE: {
          endpoint: '/transports/:id/validate',
          method: 'PUT',
          description: 'Validates transport with given id'
        },
        DETACH_ALL_DELIVERIES: {
          endpoint: '/transports/detach-all-deliveries/:id',
          method: 'POST',
          description: 'Detaches all deliveries from transport.'
        }
      },
      TRANSPORT_TRANSPORT_RELATIONS: {
        SAVE_TRANSPORT_COMBINING: {
          endpoint: '/transport-transport-relations/transport-combining',
          method: 'POST',
          description: 'Saves relation of selected transports'
        },
        CLEAR_ADVICES_AND_TWS: {
          endpoint: '/transport-transport-relations/clear-before-combining',
          method: 'POST',
          description: 'Removes time windows and advices from all combined transports'
        },
        DELETE_ALL_COMBINED_RELATIONS: {
          endpoint: '/transport-transport-relations/delete-all-combined-relations/:id',
          method: 'DELETE',
          description: 'Delete all relations of selected transport'
        },
        GET_HAS_TO_WAIT_FOR_IDENTIFIER: {
          endpoint: '/transport-transport-relations/has-to-wait-for-identifier',
          method: 'GET',
          description: 'Generates next identifier for Has To Wait For relation'
        }
      },
      USER: {
        ACTIVATE_ACCOUNT: {
          endpoint: '/activateAccount',
          method: 'GET',
          description: 'Activates account with given hash'
        },
        DISABLE: {
          endpoint: '/users/:userId/disable',
          method: 'PUT',
          description: 'Disables user with given user id'
        },
        REMIND_PASSWORD: {
          endpoint: '/remind',
          method: 'POST',
          description: 'Sends reset password link to user email.'
        },
        REJECT_STATUTE: {}
      },
      WEIGHTS: {
        MOCK: {
          endpoint: '/weights/weightMock',
          method: 'GET',
          description: 'Mock automatic weight'
        },
        SYNCHRONIZE_WEIGHTS: {
          endpoint: '/synchronizeWeights',
          method: 'POST',
          description: 'Synchronizes weights with third party API'
        }
      },
      QUEUES: {
        ENABLE: {
          endpoint: '/queues/:queueId/enable',
          method: 'PUT',
          description: 'Enables queue with given id'
        },
        DISABLE: {
          endpoint: '/queues/:queueId/disable',
          method: 'PUT',
          description: 'Disables queue with given id'
        }
      },
      PLANNING: {
        GENERATE_RANDOM_DELIVERY_NUMBER: {
          endpoint: '/planning/random-delivery-number',
          method: 'GET',
          description: 'Returns random not used delivery number'
        },
        CREATE_BASIC_TRANSPORT: {
          endpoint: '/transports/createBasicTransport',
          method: 'POST',
          description: 'Creates basic transport for planning.'
        },
        DELETE_BASIC_TRANSPORT: {
          endpoint: '/transports/:transportId/deleteBasicTransport',
          method: 'DELETE',
          description: 'Delete basic transport.'
        },
        ADD_DELIVERY_TO_TRANSPORT: {
          endpoint: '/planning/addDeliveryToTransport',
          method: 'POST',
          description: 'Adds delivery to transport'
        },
        REMOVE_DELIVERY_FROM_TRANSPORT: {
          endpoint: '/planning/removeDeliveryFromTransport',
          method: 'POST',
          description: 'Removes delivery from transport'
        },
        RELEASE_TRANSPORT: {
          endpoint: '/planning/releaseTransport',
          method: 'POST',
          description: 'Create transport from planning transport with list of deliveries'
        }
      },
      SYNCHRONISE_CARRIERS_DATA: {
        SYNC: {
          endpoint: '/companies/synchroniseCarriersData',
          method: 'GET',
          description: 'Synchronises carriers data with use of MSH'
        }
      },
      MANAGE_JOBS: {
        START: {
          endpoint: '/managable-jobs/start',
          method: 'POST',
          description: 'Startuje joba'
        },
        STOP: {
          endpoint: '/managable-jobs/stop',
          method: 'POST',
          description: 'Zatrzymuje joba'
        }
      },
      MARCOS: {
        REFRESH_RESOURCES: {
          endpoint: '/msh/refresh-resources/:transportId',
          method: 'GET',
          description: 'Send refresh resources request to msh'
        }
      },
      DELIVERY: {
        DETACH_FROM_TRANSPORT: {
          endpoint: '/deliveries/disconnect/:id',
          method: 'POST',
          description: 'Detach delivery from transport'
        },
        UPDATE_INVOICE_DATA: {
          endpoint: '/deliveries/editInvoice/:id',
          method: "PUT",
          description: 'Update invoice data in delivery'
        },
        CREATE_DELIVERY_FROM_TRANSPORT: {
          endpoint: '/deliveries/createFromTransport/',
          method: 'POST',
          description: 'Create delivery from transport'
        }
      },
      PRICE_LIST: {
        EXCEL_REPORT: {
          endpoint: '/reports/price-list',
          method: 'GET',
          description: 'Generate and send price list xls report to email'
        }
      },
      OFFERS: {
        MARK_AS_PREFERRED: {
          endpoint: '/offers/:id/markAsPreferred',
          method: 'PUT',
          description: 'Marks offer with given id as preferred'
        }
      }
    })
  });

  _exports.default = _default;
});