define("apollo/pods/components/transports/search-transports/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: Ember.A(['pull-left']),
    sessionAccount: Ember.inject.service('session-account'),
    scannerInputService: Ember.inject.service('scanner-input-service'),
    intl: Ember.inject.service(),
    actionName: '',
    showSearchButton: false,
    startProxy: null,
    stopProxy: null,
    dateFilterPropertyProxy: null,
    globalSearchFilterOption: 'ALL',
    globalSearchFilterOptionForApiV2: 'CAR_REGISTRATION_NUMBER',
    GLOBAL_SEARCH_FILTER_OPTIONS: Ember.A(['ALL', 'CAR_REGISTRATION_NUMBER', 'ADVICE_DRIVER_NAME']),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    searchParams: {},
    // rzeczywista zmienna propagowana po całym systemie
    queryProxy: '',
    // zmienna działająca tylko w ramach komponentu
    filterMode: null,

    init() {
      this._super(...arguments);

      const dateFilterProperty = this.get('transportType.dateFilterProperty');
      this.set('dateFilterPropertyProxy', dateFilterProperty);
    },

    firstKeyPressedTime: null,

    keyPress(e) {
      const self = this;
      const inputElement = $(document.getElementById('transport-search-input').children[0]);
      let inputValue = inputElement.val();

      if (inputValue.length === 0) {
        this.set('firstKeyPressedTime', Date.now());
      }

      if (e.keyCode === self.get('scannerInputService').ENTER_KEY_CODE) {
        if (inputValue.length === self.get('scannerInputService').BARCODE_LENGTH) {
          const lastKeyPressedTime = Date.now();
          const elapsedTime = Math.floor(lastKeyPressedTime - self.get('firstKeyPressedTime'));

          if (elapsedTime < 100) {
            if (self.get('scannerInputService').verifyChecksum(inputValue)) {
              inputElement.val(inputValue.substring(0, inputValue.length - 1));
            } else {
              alert(self.get('intl').t('table.scanBarcodeError'));
              inputElement.val('');
            }
          }
        } else {
          inputValue += e.key;
        }
      }
    },

    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    didReceiveAttrs() {
      this._super();

      if (this.get('sessionAccount').isGroupFilterEnabled() && !this.get('filterMode')) {
        const filteringModeValue = localStorage.filteringMode || 'user';
        this.set('filterMode', filteringModeValue);
      }

      let showSearchButton = false;

      if (this.get('query') && !this.get('queryProxy')) {
        this.set('queryProxy', this.get('query'));
        showSearchButton = true;
      }

      if (this.get('start') && !this.get('startProxy')) {
        this.set('startProxy', this.get('start'));
        showSearchButton = true;
      }

      if (this.get('stop') && !this.get('stopProxy')) {
        this.set('stopProxy', this.get('stop'));
        showSearchButton = true;
      }

      if (showSearchButton) {
        this.set('showSearchButton', true);
      }
    },

    showScanBarcodeButton: Ember.computed('transportTypeMode', function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_SCAN_BARCODES');
    }),
    _setShowSearchButtonOnce: function () {
      if (this.get('queryProxy') !== '' || this.get('startProxy') || this.get('stopProxy')) {
        this.set('showSearchButton', true);
      }
    },
    // eslint-disable-next-line ember/no-observers
    setShowSearchButton: Ember.observer('queryProxy', 'startProxy', 'stopProxy', function () {
      Ember.run.once(this, '_setShowSearchButtonOnce');
    }),
    showUserAndGroupFilter: Ember.computed('', function () {
      return this.get('sessionAccount').isGroupFilterEnabled();
    }),
    _cleanParamsOnce: function () {
      this.set('actionName', 'clearQueryParams');
      this.sendAction('actionName', '');
      this.setProperties({
        queryProxy: '',
        start: '',
        stop: '',
        dateFilterProperty: this.get('transportType.dateFilterProperty'),
        startProxy: '',
        stopProxy: '',
        showSearchButton: false
      });
      this.set('searchParams.query', '');
    },
    _cleanParams: function () {
      Ember.run.once(this, '_cleanParamsOnce');
    },
    _setParams: function () {
      this.set('actionName', 'setQueryParams');
      const startString = this.get('startProxy') ? moment(this.get('startProxy')[0]).format('YYYY-MM-DD') : '';
      const stopString = this.get('stopProxy') ? moment(this.get('stopProxy')[0]).format('YYYY-MM-DD') : '';
      this.sendAction('actionName', this.get('queryProxy'), {
        globalSearchFilterOption: this.get('globalSearchFilterOption')
      }, startString, stopString, this.get('dateFilterPropertyProxy'));
    },
    _setParamsOnce: function () {
      Ember.run.once(this, '_setParams');
    },
    _setFilterModeOnce: function (mode) {
      this.setProperties({
        filterMode: mode
      });
      localStorage.setItem('filteringMode', mode);
      this.set('actionName', 'setFilterMode');
      this.sendAction('actionName', mode);
    },
    actions: {
      setParams: function () {
        this._setParams();
      },
      cleanParams: function () {
        this._cleanParams();
      },
      scanBarcode: function () {
        this._cleanParams();

        this.set('actionName', 'showModal');
        const model = {
          searchParams: this.get('searchParams'),
          searchComponent: this
        };
        this.sendAction('actionName', 'components.transports.search-transports.scan-barcode-modal', model);
      },
      _toggleDaysLimitEnabledOnce: function () {
        const daysLimitEnabled = this.get('searchParams.daysLimitEnabled');
        this.set('actionName', 'setDaysLimitEnabled');
        this.sendAction('actionName', !daysLimitEnabled);
      },
      toggleDaysLimitEnabled: function () {
        Ember.run.once(this, '_toggleDaysLimitEnabledOnce');
      },
      setFilterMode: function (mode) {
        Ember.run.once(this, '_setFilterModeOnce', mode);
      }
    }
  });

  _exports.default = _default;
});