define("apollo/pods/components/transport-row-delivery-innvoice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uqyDMjeb",
    "block": "{\"symbols\":[\"delivery\"],\"statements\":[[7,\"div\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"transport\",\"deliveries\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"components/transport-row-delivery-innvoice/delivery-invoice-data\",null,[[\"delivery\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transport-row-delivery-innvoice/template.hbs"
    }
  });

  _exports.default = _default;
});