define("apollo/pods/components/route/form/route-custom-fields-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const self = this;
      const route = this.get('route');
      Ember.run.once(() => {
        route.get('routeCustomFields').then(() => self.createMissingCustomFieldValues(route));
      });
    },

    createMissingCustomFieldValues: function (route) {
      const self = this;
      const currentPairs = route ? route.get('customFieldPairs') : this.get('route.customFieldPairs');
      currentPairs.forEach(pair => {
        const definition = pair.definition;

        if (definition.get('visibleInDelivery')) {
          return;
        }

        if (!pair.value) {
          console.debug(`Dodajemy do trasy ${route.get('id')} puste/nowe custom field ${definition.get('name')}...`);
          self.get('store').createRecord('routeCustomField', {
            route,
            definition
          });
        }
      });
    }
  });

  _exports.default = _default;
});