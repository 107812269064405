define("apollo/pods/price-list/index/controller", ["exports", "apollo/pods/transports/index/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    actionProgressToast: Ember.inject.service('action-progress-service'),
    availableTransportTypes: Ember.computed('model.availableTransportTypes', function () {
      return this.get('model.availableTransportTypes');
    }),
    shouldDisplayTransportTypeButtons: Ember.computed('availableTransportTypes.length', function () {
      return this.get('availableTransportTypes.length') > 1;
    }),
    transportType: Ember.computed('availableTransportTypes.@each.alias', 'transportTypeMode', function () {
      return this.get('availableTransportTypes').find(tt => tt.get('alias') === this.get('transportTypeMode'));
    }),
    shouldDisplayCreateNewRouteButton: Ember.computed('transportType', function () {
      return this.get('transportType').findPositiveAuthorityRestrictionsByActionType('ROLE_CAN_ADD_ROUTE', this.get('transportType.id')).length > 0;
    }),
    showExportXlsButton: Ember.computed('model.showExportXlsButton', function () {
      return this.get('model.showExportXlsButton');
    }),
    _generateXlsReport: async function () {
      const response = await this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.PRICE_LIST.EXCEL_REPORT);

      if (response.ok) {
        this.actionProgressToast.showSuccessToast(this.intl.t('transportsToExcel.XLSGenerateMessage'));
      } else {
        if (response.status === 404) {
          this.actionProgressToast.showErrorToast(this.get('intl').t('transportsToExcel.errors.XLSGenerateEmailErrorMessage'));
          return;
        }

        const err = await response.json();
        this.actionProgressToast.showErrorToast(this.get('intl').t('notificationToast.titles.error'), err.errors);
      }
    },
    actions: {
      setTransportTypeMode(mode) {
        console.log(`Cenniki: Przechodzimy do typu ${mode}...`);
        this.transitionToRoute('price-list.index', mode, {
          queryParams: {
            max: this.get('max'),
            page: 1
          }
        });
      },

      showCreateRouteForm() {
        const routeCustomFieldDefinitions = this.get('transportType.sortedFieldDefinitions').filter(d => d.get('id') && d.get('enabled') && d.get('includedInPriceList'));
        const routeCustomFields = routeCustomFieldDefinitions.map(definition => {
          return this.get('store').createRecord('routeCustomField', {
            definition
          });
        });
        const route = this.get('store').createRecord('route', {
          transportType: this.get('transportType'),
          routeCustomFields
        });
        const routeAddressLoad = this.get('store').createRecord('routeAddress', {
          stepType: 'load',
          idx: 0,
          stepTypeIdx: 0
        });
        const routeAddressUnload = this.get('store').createRecord('routeAddress', {
          stepType: 'unload',
          idx: 1,
          stepTypeIdx: 0
        });
        route.get('routeAddresses').addObject(routeAddressLoad);
        route.get('routeAddresses').addObject(routeAddressUnload);
        this.send('showModal', 'routes.modals.route-form.show', route);
      },

      generateExcelReport() {
        Ember.run.once(this, '_generateXlsReport');
      }

    }
  });

  _exports.default = _default;
});