define("apollo/pods/components/advice-handler/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    sessionAccount: Ember.inject.service('session-account'),
    showAdviceButton: Ember.computed('advice', 'transport.{firstUnconfirmedCheckpoint,advice.id,carrierCompany.id,transportType.carrierMustBeSelectedBeforeAdvice,isRelatedTransportInTransportCombining}', 'masterTransport.advice.id', 'masterTransport', function () {
      const advice = this.get('advice');

      if (advice.get('id') || this.get('transport.transportType.carrierMustBeSelectedBeforeAdvice') && !this.get('transport.carrierCompany.id')) {
        return false;
      }

      if (this.get('transport.isRelatedTransportInTransportCombining')) {
        const transportCombiningMasterTransport = this.get('transport.masterTransportInTransportCombining');

        if (transportCombiningMasterTransport.get('advice.id')) {
          return false;
        }
      } // okno powinno być dodane przed awizacją ale jeszcze nie jest dodane (transport.timeWindows.length === 0) dlatego nie wyświetlamy przycisku.


      if (this.get('transport.transportType.selectionOfWindowBeforeAdvice') && this.get('transport.timeWindows.length') === 0) {
        return false;
      }

      if (!this.get('transport.transportType.adviceSeparatedFromWindow')) {
        return this.get('transport').actionCanBePerformed('ADD_ADVICE');
      }

      return this.get('transport').actionCanBePerformed('ADD_ADVICE');
    }),
    showCreateWindowButton: Ember.computed('' + 'transport.{timeWindows.length,firstUnconfirmedCheckpoint,advice.id,carrierCompany.id,transportType.carrierMustBeSelectedBeforeAdvice,isRelatedTransportInTransportCombining}', function () {
      if (this.get('transport.stepsWithWarehouse.length') === 0) {
        return false;
      }

      if (this.get('transport.isRelatedTransportInTransportCombining')) {
        const master = this.get('transport.masterTransportInTransportCombining');

        if (master && master.get('timeWindows.length') > 0) {
          return false;
        }
      }

      if (this.get('transport.timeWindows.length') > 0) {
        return false;
      }

      const advice = this.get('transport.advice');

      if (!advice.get('id') && !this.get('transport.transportType.selectionOfWindowBeforeAdvice')) {
        return false;
      }

      return this.get('authorityCheckerService').actionCanBePerformed('ADD_TIME_WINDOW', this.get('transport'));
    }),
    disableAdviceForSupplierDueToModeOfTransportation: Ember.computed('transport.requiresModeOfTransportationVerification', 'sessionAccount.currentUser.company.partnershipType', function () {
      const partnershipType = this.get('sessionAccount').get('currentUser').get('company.partnershipType.name');
      return !!(this.get('transport.transportType.requiresModeOfTransportationVerification') && partnershipType === 'SUPPLIER');
    }),
    advice: Ember.computed('masterTransport', 'transport.advice', 'masterTransport.advice', function () {
      if (this.get('transport.isRelatedTransportInTransportCombining')) {
        return this.get('transport.masterTransportInTransportCombining.advice');
      }

      return this.get('transport.advice');
    }),
    showTrailerInsteadOfTruckRegNum: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('DISPLAY_TRAILER_REG_NUMBER_IN_ADVICE_COLUMN') === 'true';
    }),
    adviceTrailerRegistrationNumber: Ember.computed('advice', 'advice.trailerRegistrationNumber', function () {
      return this.get('advice').get('trailerRegistrationNumber');
    }),
    adviceCarRegistrationNumber: Ember.computed('advice', 'advice.carRegistrationNumber', function () {
      return this.get('advice').get('carRegistrationNumber');
    })
  });

  _exports.default = _default;
});