define("apollo/pods/components/transaction/accept-transaction-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',
    showAcceptationModal: false,
    transactionPrice: Ember.computed({
      get(key) {
        return 0;
      },

      set(key, value) {
        return value;
      }

    }),
    transactionCurrency: Ember.computed({
      get(key) {
        return null;
      },

      set(key, value) {
        return value;
      }

    }),
    actions: {
      onButtonClick: function () {
        const transactionId = this.get('transport.transaction.id');
        this.store.findRecord('transaction', transactionId).then(transaction => {
          this.set('transactionPrice', transaction.get('price'));
          this.set('transactionCurrency', transaction.get('currency'));
          this.toggleProperty('showAcceptationModal');
        });
      },
      onAcceptTransaction: function () {
        this.acceptTransaction();
      },
      toggleModal: function () {
        this.toggleProperty('showAcceptationModal');
      }
    }
  });

  _exports.default = _default;
});