define("apollo/pods/components/packages-table/templates/package-type-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    packageTypeName: Ember.computed('context.row.name', function () {
      return this.intl.t(`packageTypes.${this.context.row.get('name')}.label`);
    })
  });

  _exports.default = _default;
});