define("apollo/pods/components/transport-row-delivery-innvoice/delivery-invoice-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nUIodUdJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"showInfo\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[23,[\"delivery\",\"invoiceNumber\"]],false],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"fa fa-circle-info\"],[9],[10],[0,\"\\n        \"],[5,\"ember-tooltip\",[],[[],[]],{\"statements\":[[0,\"\\n            \"],[1,[27,\"show-delivery-invoice-details\",null,[[\"delivery\"],[[23,[\"delivery\"]]]]],false],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"canEditInvoice\"]]],null,{\"statements\":[[0,\"            \"],[7,\"a\"],[11,\"class\",\"clickable\"],[11,\"data-button-name\",\"edit-price-button\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-edit\"],[9],[10],[3,\"action\",[[22,0,[]],\"editInvoice\",[23,[\"delivery\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transport-row-delivery-innvoice/delivery-invoice-data/template.hbs"
    }
  });

  _exports.default = _default;
});