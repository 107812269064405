define("apollo/pods/auctions/modals/auction-details-form/controller", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    isCarrier: Ember.computed('model.isCarrier', function () {
      return this.get('model.isCarrier');
    }),
    transport: Ember.computed('model.transport', function () {
      return this.get('model.transport');
    }),
    title: Ember.computed('transport.{identifier,formattedCities}', function () {
      return `${this.get('transport.identifier')}: ${this.get('transport.formattedCities')}`;
    }),
    lastAuction: Ember.computed('transport.auctions.lastObject', function () {
      return this.get('transport.auctions.lastObject');
    }),
    previousAuctions: Ember.computed('transport.auctions.[]', 'lastAuction', function () {
      return this.get('transport.auctions').filter(a => a.get('id') !== this.get('lastAuction.id'));
    }),
    idxOfLastCompany: Ember.computed('lastAuction.companies.length', function () {
      return this.get('lastAuction.companies.length') - 1;
    }),
    isCompaniesSectionVisible: Ember.computed('', function () {
      return false;
    }),
    isPreviousAuctionsSectionVisible: Ember.computed('', function () {
      return false;
    }),
    showFinishAuctionButton: Ember.computed('transport.{activeAuction,transportType.id}', function () {
      const auctionSetting = this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));

      if (!auctionSetting) {
        return false;
      }

      return this.get('transport.activeAuction') && auctionSetting.get('showFinishAuctionButton');
    }),
    actions: {
      toggleCompaniesSection() {
        this.toggleProperty('isCompaniesSectionVisible');
      },

      togglePreviousAuctionsSection() {
        this.toggleProperty('isPreviousAuctionsSectionVisible');
      },

      cancel() {
        console.debug('Zamykamy okienko historii aukcji...');
        this.setProperties({
          isCompaniesSectionVisible: false,
          isPreviousAuctionsSectionVisible: false
        });
        const collapseElement = (0, _jquery.default)('.collapse');
        collapseElement.collapse();
        collapseElement.removeClass('in');
      },

      finishAuction() {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/auctions/' + self.get('transport.activeAuction.id') + '/finish',
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(respond => {
          if (!respond.error) {
            self.send('hideModal');
            self.get('transport.activeAuction').reload();
          }
        });
      }

    }
  });

  _exports.default = _default;
});