define("apollo/pods/components/packages-table/templates/package-stacking-factor-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    contextValue: Ember.computed('context.row', function () {
      return this.context.row;
    }),
    stackingFactorValue: Ember.computed('contextValue', function () {
      const rowValue = this.get('contextValue');

      if (isNaN(rowValue) || rowValue === null) {
        return '';
      }

      return this.intl.t(`transport.packagesSection.stackingFactorOptions.${this.context.row}`);
    })
  });

  _exports.default = _default;
});