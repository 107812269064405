define("apollo/pods/components/custom-field-definition-automatic-value/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      toggleAutomaticValueEnabled() {
        this.toggleProperty('model.automaticValueEnabled');
      }

    }
  });

  _exports.default = _default;
});