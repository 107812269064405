define("apollo/pods/components/auction/auction-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    showCreateAuctionButton: Ember.computed('transport.{active,carrierCompany.id,activeAuction,transaction,checkpoints.@each.executionDate}', function () {
      return !this.get('transport.carrierCompany.id') && !this.get('transport.activeAuction') && !this.get('transport.transaction.content') && this.get('transport').actionCanBePerformed('CREATE_AUCTION');
    }),
    auctions: Ember.computed('transport.auctions', function () {
      return this.get('transport.auctions');
    }),
    offerCompaniesIds: Ember.computed('transport.offerCompanies.@each.id', function () {
      return this.get('transport.offerCompanies').mapBy('id');
    }),
    isCarrier: Ember.computed('sessionAccount.currentCompany.isCarrier', function () {
      return this.get('sessionAccount.currentCompany.isCarrier');
    }),
    showCarrierAuctionDetailsButton: Ember.computed('isCarrier', 'transport.@each.offer', 'offerCompaniesIds', function () {
      const currentCompany = this.get('sessionAccount.currentCompany');
      return this.get('isCarrier') && this.get('transport').actionCanBePerformed('BID_AUCTION') && this.get('offerCompaniesIds').some(id => {
        return id === currentCompany.get('id');
      });
    }),
    _showAuctionDetailsButton: Ember.computed('transport.{auctions.length,activeAuction}', function () {
      const canCreateAuction = this.get('transport').actionCanBePerformed('CREATE_AUCTION');
      const canAccessAuctionData = this.get('transport').actionCanBePerformed('ROLE_ACCESS_AUCTION_DATA');
      return this.get('transport.auctions.length') > 0 && canCreateAuction && canAccessAuctionData;
    }),
    showAuctionDetailsButton: Ember.computed('showCarrierAuctionDetailsButton', '_showAuctionDetailsButton', function () {
      return this.get('_showAuctionDetailsButton') || this.get('showCarrierAuctionDetailsButton');
    }),
    canSeeCurrentPlaceInAuction: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_SEE_CURRENT_PLACE_IN_AUCTION');
    }),
    biddingIsEnabled: Ember.computed('transport.{active,transportType.forcedPollRequestTime,activeAuction}', function () {
      return !this.get('transport.transportType.forcedPollRequestTime') && this.get('transport').actionCanBePerformed('BID_AUCTION');
    }),
    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }

    }
  });

  _exports.default = _default;
});