define("apollo/pods/components/transport/advice-date-restrictions-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),

    init() {
      this._super(...arguments);

      const step = this.get('step');

      if (step.get('warehouse.content') !== null) {
        return;
      }

      if (step.get('transport').get('id') || !(step.get('transport') * step.get('id'))) {
        return;
      }

      const tt = step.get('transport').get('transportType') ? step.get('transport').get('transportType') : this.get('transportType');
      let defaultStartHour = tt.get('defaultLoadStepStartHour');
      let defaultEndHour = tt.get('defaultLoadStepEndHour'); // Defaultowo ustawialiśmy 00:00, po dodaniu tych nowych ustawień,
      // jeśli jakiś klient tego nie uzupełni, dalej powinien mieć 00:00

      if (defaultStartHour === '' || defaultStartHour === null || defaultStartHour === undefined) {
        defaultStartHour = '00:00';
      }

      if (defaultEndHour === '' || defaultEndHour === null || defaultEndHour === undefined) {
        defaultEndHour = '00:00';
      }

      const startTimeArray = defaultStartHour.split(':');
      const endTimeArray = defaultEndHour.split(':');
      const startHour = Number(startTimeArray[0]);
      const startMinute = Number(startTimeArray[1]);
      const endHour = Number(endTimeArray[0]);
      const endMinute = Number(endTimeArray[1]);
      const newStartDate = moment(this.get('step.minAdviceDate')).set('hour', startHour).set('minute', startMinute);
      const newEndDate = moment(this.get('step.maxAdviceDate')).set('hour', endHour).set('minute', endMinute);
      Ember.run.next(() => {
        step.set('minAdviceDate', new Date(newStartDate));
        step.set('maxAdviceDate', new Date(newEndDate));
      });
    },

    timeWindowOffsetInterval: function () {
      return this.get('step.warehouse.timeWindowOffsetInterval') ? this.get('step.warehouse.timeWindowOffsetInterval') : 15;
    }.property('step.warehouse.id'),
    formattedMinAdviceDate: Ember.computed('step.minAdviceDate', function () {
      return moment(this.get('step').get('minAdviceDate')).format('YYYY-MM-DD HH:mm');
    }),
    formattedMaxAdviceDate: Ember.computed('step.maxAdviceDate', function () {
      return moment(this.get('step').get('maxAdviceDate')).format('YYYY-MM-DD HH:mm');
    }),
    warehouseOpenHoursMinAdviceDateObserver: Ember.observer('step.warehouse', function () {
      this.adjustAdviceRestrictionHour('step.minAdviceDate', 'workStartHour');
    }),
    warehouseOpenHoursMaxAdviceDateObserver: Ember.observer('step.warehouse', function () {
      this.adjustAdviceRestrictionHour('step.maxAdviceDate', 'workEndHour');
    }),
    adjustAdviceRestrictionHour: function (dateName, warehousePropertyName) {
      const currentAdviceDate = this.get(dateName);

      if (!currentAdviceDate || !this.get('step.warehouse.id')) {
        return;
      }

      const currentAdviceHour = moment(currentAdviceDate).hours();
      const warehouseStart = this.get('step.warehouse.workStartHour');
      const warehouseEnd = this.get('step.warehouse.workEndHour');

      if (warehouseStart <= currentAdviceHour && currentAdviceHour <= warehouseEnd) {
        return;
      }

      const correctHour = this.get(`step.warehouse.${warehousePropertyName}`);
      const correctDate = moment(currentAdviceDate).hours(correctHour).toDate();
      this.set(dateName, correctDate);
    },
    actions: {
      setMinAdviceDate(step, dateValues) {
        Ember.run.next(() => {
          const oldMinAdviceDate = step.get('minAdviceDate');
          const oldMaxAdviceDate = step.get('maxAdviceDate');
          const newMinAdviceDate = dateValues[0];
          let newMaxAdviceDate = null;

          if (oldMinAdviceDate) {
            const difference = newMinAdviceDate.getTime() - oldMinAdviceDate.getTime();
            newMaxAdviceDate = moment(oldMaxAdviceDate.getTime() + difference);
          } else {
            newMaxAdviceDate = moment(newMinAdviceDate).add(1, 'days');
          }

          step.set('minAdviceDate', newMinAdviceDate);
          step.set('maxAdviceDate', new Date(newMaxAdviceDate));
        });
      },

      setMaxAdviceDate(step, dateValues) {
        Ember.run.next(() => {
          step.set('maxAdviceDate', dateValues[0]);
        });
      }

    }
  });

  _exports.default = _default;
});