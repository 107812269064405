define("apollo/pods/components/auction/bid-auction-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pJqsEYAb",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"disableForSupplier\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn btn-sm vertical-margin-small\"],[11,\"data-button-name\",\"offer\"],[12,\"style\",[21,\"style\"]],[11,\"disabled\",\"disabled\"],[12,\"title\",[28,[[27,\"t\",[\"transport.companyNotQualified\"],null],[27,\"t\",[\"transport.forAuction\"],null]]]],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"auction.bid\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"showOfferForm\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn btn-sm vertical-margin-small\"],[11,\"data-button-name\",\"offer\"],[12,\"style\",[21,\"style\"]],[9],[0,\"\\n        \"],[1,[27,\"t\",[\"auction.bid\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"showOfferForm\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/auction/bid-auction-button/template.hbs"
    }
  });

  _exports.default = _default;
});