define("apollo/pods/components/transport-row-delivery-innvoice/delivery-invoice-data/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modalService: Ember.inject.service('modal-service'),
    authorityCheckerService: Ember.inject.service('authority.authority-checker-service'),
    showInfo: Ember.computed('{delivery.freightCost,invoiceNumber}', function () {
      const cost = this.get('delivery.freightCost');
      return cost && cost >= 0 || !!this.get('delivery.invoiceNumber');
    }),
    canEditInvoice: Ember.computed('delivery.transportType.id', function () {
      return this.authorityCheckerService.findPositiveAuthorityRestrictionsByActionType('ROLE_EDIT_DELIVERY_INVOICE_DATA', this.get('delivery.transportType.id'));
    }),
    actions: {
      editInvoice: function (delivery) {
        this.get('modalService').showModal(this.get('modalService').MODALS.DELIVERIES.EDIT_INVOICE, delivery);
      }
    }
  });

  _exports.default = _default;
});