define("apollo/pods/components/transport-and-deliveries-custom-fields/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e7teQCXn",
    "block": "{\"symbols\":[\"pair\"],\"statements\":[[4,\"if\",[[23,[\"definition\",\"visibleInDelivery\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"deliveriesCfs\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,1,[\"definition\",\"id\"]],[23,[\"definition\",\"id\"]]],null]],null,{\"statements\":[[0,\"            \"],[1,[27,\"custom-field-value-formatted\",null,[[\"definition\",\"value\"],[[22,1,[\"definition\"]],[22,1,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"\\n\"],[1,[27,\"custom-field-value-formatted\",null,[[\"definition\",\"value\"],[[23,[\"currentTransportCf\",\"definition\"]],[23,[\"currentTransportCf\",\"value\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/transport-and-deliveries-custom-fields/template.hbs"
    }
  });

  _exports.default = _default;
});