define("apollo/pods/delivery/modals/edit-invoice/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    errors: Ember.A([]),
    intl: Ember.inject.service(),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    delivery: Ember.computed('model', function () {
      return this.get('model');
    }),
    actions: {
      editInvoice: async function () {
        const delivery = this.get('delivery');
        const path = this.get('apolloApiService').APOLLO_API.DELIVERY.UPDATE_INVOICE_DATA;

        try {
          const response = await this.get('apolloApiService').callApolloApi(path, {
            id: delivery.get('id')
          }, {
            body: JSON.stringify(delivery)
          });

          if (response.ok) {
            delivery.reload();
            this.send('hideModal');
          } else {
            const error = await response.json();
            this.set('errors', error.errors);
          }
        } catch (error) {
          this.set('errors', [this.get('intl').t('common.defaultError')]);
          console.error(error);
        }
      },
      cancel: function () {
        this.get('delivery').rollbackAttributes();
        this.send('hideModal');
      },
      close: function () {
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});