define("apollo/pods/components/planning/not-planned-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    uuidService: Ember.inject.service('uuid-service'),
    modalService: Ember.inject.service('modal-service'),
    transportTypeSettingService: Ember.inject.service('transport-type-settings-service'),
    deliveryService: Ember.inject.service('delivery-service'),
    totalWeight: Ember.computed('deliveries.@each.grossWeight', function () {
      const deliveries = this.get('deliveries');
      let totalWeight = 0;
      deliveries.forEach(delivery => {
        totalWeight += delivery.get('grossWeight');
      });
      return totalWeight;
    }),
    customFieldsPairs: Ember.computed('customFields', function () {
      const definitions = this.get('customFields');
      return this.deliveryService.findCustomFieldPairs(definitions);
    }),
    canCreateDelivery: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_CREATE_DELIVERY');
    }),
    _autoGenerateDeliveryNumber: async function (delivery) {
      if (await delivery.get('deliverySetting.autoGenerateDeliveryNumber')) {
        let randomDeliveryNumber;
        const response = await this.get('apolloApiService').callApolloApi(this.get('apolloApiService').APOLLO_API.PLANNING.GENERATE_RANDOM_DELIVERY_NUMBER, null, null);

        if (response.ok) {
          const json = await response.json();
          randomDeliveryNumber = json.deliveryNumber;
        } else {
          randomDeliveryNumber = this.uuidService.uuid();
        }

        delivery.set('deliveryNumber', randomDeliveryNumber);
      }
    },
    createSimplePackaging: function (delivery) {
      if (delivery.get('id') || !delivery.get('deliverySetting.showSimplePackagingSection')) {
        return;
      }

      this.get('store').peekAll('packageTypeTransportType').filter(i => i.get('transportType.id') === delivery.get('transportType.id')).forEach(type => {
        const deliveryPackaging = this.get('store').createRecord('deliveryPackaging', {
          loadStep: this.get('delivery.loadStep'),
          unloadStep: this.get('delivery.unloadStep'),
          packageType: type.get('packageType'),
          enabled: true,
          quantity: 0
        });
        delivery.get('packaging').addObject(deliveryPackaging);
      });
    },
    actions: {
      newDelivery: async function () {
        const self = this;
        const transportType = await this.get('transportType');
        const delivery = this.store.createRecord('delivery', {
          transportType,
          deliverySetting: await this.transportTypeSettingService.getDeliverySettings(transportType)
        });
        await this.deliveryService.autoGenerateDeliveryNumber(delivery);
        const isLoadStepInWarehouseByDefault = transportType.get('isLoadStepInWarehouseByDefault');
        const isUnloadStepInWarehouseByDefault = transportType.get('isUnloadStepInWarehouseByDefault');
        const loadContactPersonDefaultName = this.sessionAccount.get('currentUser.name');
        const loadContactPersonDefaultPhone = this.sessionAccount.get('currentUser.mobilePhoneNumber');
        delivery.set('loadStep', self.get('store').createRecord('step', {
          ownedByPrincipal: isLoadStepInWarehouseByDefault,
          stepTypeName: 'load',
          orderInTransport: 0,
          contactPerson: loadContactPersonDefaultName,
          contactPersonMobilePhoneNumber: loadContactPersonDefaultPhone
        }));
        delivery.set('unloadStep', self.get('store').createRecord('step', {
          ownedByPrincipal: isUnloadStepInWarehouseByDefault,
          stepTypeName: 'unload',
          orderInTransport: 1
        }));
        delivery.set('packaging', Ember.A([]));
        this.deliveryService.createSimplePackaging(delivery);
        const model = {
          customFields: this.get('customFieldsPairs'),
          delivery,
          transportType,
          packageTypes: this.get('packageTypes')
        };
        const modalPath = this.get('modalService').MODALS.DELIVERIES.CREATE;
        this.get('modalService').showModal(modalPath, model);
      },

      releaseDelivery(obj) {
        return this.apolloApiService.callApolloApi(this.apolloApiService.APOLLO_API.PLANNING.REMOVE_DELIVERY_FROM_TRANSPORT, null, {
          body: JSON.stringify({
            deliveryId: obj.get('id')
          })
        });
      }

    }
  });

  _exports.default = _default;
});