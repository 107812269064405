define("apollo/services/transport-relations/relation-sequence-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    apolloApiService: Ember.inject.service('api.apollo-api-service'),

    async getHasToWaitForNextIdentifier() {
      const path = this.apolloApiService.APOLLO_API.TRANSPORT_TRANSPORT_RELATIONS.GET_HAS_TO_WAIT_FOR_IDENTIFIER;
      const response = await this.apolloApiService.callApolloApi(path);

      if (response.ok) {
        const data = await response.json();
        return data.identifier;
      } else {
        return null;
      }
    }

  });

  _exports.default = _default;
});