define("apollo/services/uuid-service", ["exports", "uuid"], function (_exports, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    uuid: function () {
      if (this._isBrowserCryptoAvailable()) {
        return window.crypto.randomUUID();
      } else {
        return (0, _uuid.v4)();
      }
    },
    _isBrowserCryptoAvailable: function () {
      return typeof window.crypto.randomUUID === 'function';
    }
  });

  _exports.default = _default;
});