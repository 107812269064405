define("apollo/services/operator-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    getTranslation: function (isAir) {
      if (!isAir) {
        return this.intl.t('operator.label.air');
      }

      return this.intl.t('operator.label.sea');
    },
    getAirOperators: function () {
      return this.store.query('operator', {
        type: 'AIR'
      });
    },
    getSeaOperators: function () {
      return this.store.query('operator', {
        type: 'SEA'
      });
    }
  });

  _exports.default = _default;
});