define("apollo/services/enum-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    incoterms: Ember.A(['DAP', 'FCA', 'CFR', 'CIF', 'CIP', 'CPT', 'DAF', 'DDP', 'DDU', 'DEQ', 'DES', 'EXW', 'FAS', 'FOB', 'DAT'])
  });

  _exports.default = _default;
});