define("apollo/pods/components/planning/delivery-step-section/component", ["exports", "apollo/mixins/step-mixin"], function (_exports, _stepMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_stepMixin.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    classNames: Ember.A(['step-responsive-width']),
    dateUtils: Ember.inject.service('date-utils'),
    arrivalDateFormat: 'DD.MM.YYYY HH:mm',
    addNewAddress: false,
    showContactPersonDetails: Ember.computed('delivery.unloadContactPersonDetails', function () {
      return this.get('delivery.unloadContactPersonDetails') !== null;
    }),
    loadStepFormattedArrivalDate: Ember.computed('delivery.loadStep.arrivalDate', function () {
      return this.get('delivery.loadStep.arrivalDate') ? this.get('dateUtils').formatDate(this.get('delivery.loadStep.arrivalDate'), this.get('arrivalDateFormat')) : '';
    }),
    loadStepFormattedRealDate: Ember.computed('delivery.realLoadDate', function () {
      return this.get('delivery.realLoadDate') ? this.get('dateUtils').formatDate(this.get('delivery.realLoadDate'), this.get('arrivalDateFormat')) : '';
    }),
    loadStepFormattedMinAdviceDate: Ember.computed('delivery.loadStep.minAdviceDate', function () {
      return this.get('delivery.loadStep.minAdviceDate') ? this.get('dateUtils').formatDate(this.get('delivery.loadStep.minAdviceDate'), this.get('arrivalDateFormat')) : '';
    }),
    loadStepFormattedMaxAdviceDate: Ember.computed('delivery.loadStep.maxAdviceDate', function () {
      return this.get('delivery.loadStep.maxAdviceDate') ? this.get('dateUtils').formatDate(this.get('delivery.loadStep.maxAdviceDate'), this.get('arrivalDateFormat')) : '';
    }),
    unloadStepFormattedArrivalDate: Ember.computed('delivery.unloadStep.arrivalDate', function () {
      return this.get('delivery.unloadStep.arrivalDate') ? this.get('dateUtils').formatDate(this.get('delivery.unloadStep.arrivalDate'), this.get('arrivalDateFormat')) : '';
    }),
    unloadStepFormattedRealDate: Ember.computed('delivery.realUnloadDate', function () {
      return this.get('delivery.realUnloadDate') ? this.get('dateUtils').formatDate(this.get('delivery.realUnloadDate'), this.get('arrivalDateFormat')) : '';
    }),
    unloadStepFormattedMinAdviceDate: Ember.computed('delivery.unloadStep.minAdviceDate', function () {
      return this.get('delivery.unloadStep.minAdviceDate') ? this.get('dateUtils').formatDate(this.get('delivery.unloadStep.minAdviceDate'), this.get('arrivalDateFormat')) : '';
    }),
    useStepDateRange: Ember.computed('transportType.requiresAdviceDateRestrictions', function () {
      return !!this.get('transportType.requiresAdviceDateRestrictions');
    }),
    useStepArrivalDate: Ember.computed('transportType.{requiresArrivalAndDepartureDate,requiresArrivalAndDepartureDateInWarehouseStep}', function () {
      return !this.get('step.isInWarehouse') && this.get('transportType.requiresArrivalAndDepartureDate') || this.get('step.isInWarehouse') && this.get('transportTyperequiresArrivalAndDepartureDateInWarehouseStep');
    }),
    unloadStepFormattedMaxAdviceDate: Ember.computed('delivery.unloadStep.maxAdviceDate', function () {
      return this.get('delivery.unloadStep.maxAdviceDate') ? this.get('dateUtils').formatDate(this.get('delivery.unloadStep.maxAdviceDate'), this.get('arrivalDateFormat')) : '';
    }),
    loadStepAddress: Ember.computed('delivery.loadStep.{address,warehouse.address}', function () {
      return this.get('delivery.loadStep.isInWarehouse') ? this.get('delivery.loadStep.warehouse.address') : this.get('delivery.loadStep.address');
    }),
    defaultPrefix: Ember.computed('step.contactPersonMobilePhonePrefix', function () {
      return this.get('step.contactPersonMobilePhonePrefix');
    }),
    countries: Ember.computed(function () {
      return this.get('store').peekAll('country').sortBy('ibanSymbol');
    }),
    address: Ember.computed('step.address', function () {
      return this.get('step.address');
    }),
    addressSummary: Ember.computed('address.{recipient,city,postal,street}', function () {
      if (this.get('address.recipient')) {
        return this.get('address.recipient');
      }

      if (this.get('address.street')) {
        return `${this.get('address.street')}, ${this.get('address.city')}, ${this.get('address.postal')}`;
      }

      return '';
    }).readOnly(),
    companyToFindAddress: Ember.computed('step.company.id', 'sessionAccount.currentUser.company.id', function () {
      if (this.get('step.company.id')) {
        return this.get('step.company.id');
      }

      if (this.get('step.stepTypeName') === 'load' && this.transportType.get('defaultLoadCompanyId')) {
        return this.transportType.get('defaultLoadCompanyId');
      } else if (this.get('step.stepTypeName') === 'unload' && this.transportType.get('defaultUnloadCompanyId')) {
        return this.transportType.get('defaultUnloadCompanyId');
      }

      return this.get('sessionAccount.currentUser.company.id');
    }),
    stepCountryField: Ember.computed('step.address.country', function () {
      return this.get('step.address.country');
    }),
    actions: {
      setArrivalDate(step, dateValues) {
        step.set('arrivalDate', dateValues[0]);
      },

      setWarehouse(warehouse) {
        this.get('step').set('warehouse', warehouse);
        this.get('step.errors').remove('warehouse');
      },

      setAddressData(address) {
        const self = this;

        if (address.id) {
          this.get('store').findRecord('address', address.id).then(a => {
            self.get('step').set('address', a);
          });
        } else {
          this.get('step.address').setProperties({
            street: address.street,
            postal: address.postal,
            city: address.city
          });
          this.get('store').findRecord('country', address.country).then(c => {
            self.get('step.address').set('country', c);
          });
        }
      },

      removeAddress() {
        console.debug('Removing address from step..');
        this.get('step').set('address', null);
      },

      setPhonePrefix(phonePrefix) {
        this.set('step.contactPersonMobilePhonePrefix', phonePrefix);
      },

      toggleNewAddress(step) {
        if (this.get('addNewAddress')) {
          this.send('deleteAddressFromStep', step);
          this.set('addNewAddress', false);
        } else {
          this.send('setAddressForStep', step);
          this.set('addNewAddress', true);
        }
      },

      deleteAddressFromStep(step) {
        step.get('address').then(address => {
          address.unloadRecord();
          step.set('address', null);
        });
      },

      setAddressForStep(step) {
        const self = this;
        step.set('address', self.get('store').createRecord('address', {
          company: self.get('sessionAccount.currentUser.company'),
          enabled: true
        }));
      }

    }
  });

  _exports.default = _default;
});