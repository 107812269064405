define("apollo/pods/components/custom-field-definition-automatic-value/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uLencu/R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[11,\"class\",\"padding-top-big\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"custom-field-definition.automaticValue.title\"],null],false],[0,\"\\n    \"],[7,\"input\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"toggleAutomaticValueEnabled\"],null]],[12,\"checked\",[23,[\"model\",\"automaticValueEnabled\"]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"model\",\"automaticValueEnabled\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"form-group \",[27,\"if\",[[23,[\"model\",\"errors\",\"automaticValue\"]],\"has-error\"],null]]]],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"col-sm-4 control-label\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"custom-field-definition.automaticValue.define\"],null],false],[0,\":\\n            \"],[7,\"i\"],[11,\"class\",\"fa fa-info-circle\"],[12,\"title\",[27,\"t\",[\"custom-field-definition.automaticValue.hint\"],null]],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"col-sm-8\"],[11,\"data-field-name\",\"definition-automatic-value\"],[9],[0,\"\\n            \"],[1,[27,\"input\",null,[[\"class\",\"value\"],[\"form-control\",[23,[\"model\",\"automaticValue\"]]]]],false],[0,\"\\n\\n            \"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\"],[[23,[\"model\"]],\"automaticValue\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/custom-field-definition-automatic-value/template.hbs"
    }
  });

  _exports.default = _default;
});