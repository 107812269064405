define("apollo/models/packages-table-column-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Represents a column configuration for a packages table
   * @class PackagesTableColumn
   * @extends EmberObject
   * @description Defines the properties and characteristics of a packages table column {@see packages-table}
   */
  const PackagesTableColumn = Ember.Object.extend({
    /**
     * Unique identifier or property key for the column
     * @type {string}
     * @default ''
     */
    key: '',

    /**
     * Human-readable label displayed for the column
     * @type {string}
     * @default ''
     */
    label: '',

    /**
     * Indicates whether the column can be sorted
     * @type {boolean}
     * @default false
     */
    sortable: false,

    /**
     * Indicates whether the column values can be summed (e.g., for numeric columns)
     * @type {boolean}
     * @default false
     */
    summable: false,

    /**
     * Custom CSS class for the column
     * @type {string}
     * @default ''
     */
    tdClass: '',

    /**
     * Custom template for column's row rendering
     * @type {Ember.Component|null}
     * @default null
     */
    template: null,

    /**
     * Custom sum function for calculating column totals with complex logic
     *
     * @callback SumFunction
     * @param {Object} row - The current row being processed
     * @param {Array} columns - All columns in the table
     * @returns {number} The calculated value to be added to the total
     *
     * @example
     * // Example of a sum function that multiplies quantity by price
     * sumFunction: (row, columns) => {
     *   const quantity = parseFloat(row['quantity']) || 0
     *   const price = parseFloat(row['price']) || 0
     *   return quantity * price
     * }
     *
     * @example
     * // Example of a conditional sum with multiple column references
     * sumFunction: (row, columns) => {
     *   if (row['status'] === 'active') {
     *     return parseFloat(row['revenue']) || 0
     *   }
     *   return 0
     * }
     */
    sumFunction: null
  });
  var _default = PackagesTableColumn;
  _exports.default = _default;
});