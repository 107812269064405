define("apollo/pods/components/trucks/truck-type-helper-text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    intl: Ember.inject.service(),
    rounding: Ember.inject.service('rounding-service'),
    showWarning: Ember.computed('model.showWarning', function () {
      return this.get('model.showWarning');
    }),
    firstEntry: Ember.computed('truckTypeList.firstObject.[]', function () {
      return this.get('truckTypeList.firstObject');
    }),
    firstEntryIdx: Ember.computed('firstEntry.idx', function () {
      return this.get('firstEntry.truckTypeIdx');
    }),
    firstEntryName: Ember.computed('firstEntry.idx', function () {
      return this.get('firstEntry.truckTypeName');
    }),
    selectedTruckIdx: Ember.computed('model.truckType.truckTypeIdx', function () {
      return this.get('model.truckType.truckTypeIdx');
    }),
    truckHelperText: Ember.computed('truckTypeList.length', 'selectedTruckIdx', 'firstEntryName', 'firstEntryIdx', function () {
      const topIdx = this.get('firstEntryIdx');
      const topName = this.get('firstEntryName');
      const selectedIdx = this.get('selectedTruckIdx');

      if (selectedIdx === null || selectedIdx === undefined) {
        return Ember.String.htmlSafe('');
      }

      if (!topIdx && !topName) {
        // Potrzeba dwóch aut, lista jest pusta.
        const txt = this.intl.t('transport.needTwoVehicles');
        return Ember.String.htmlSafe('<span style="color:darkred;">' + txt + '</span>');
      }

      if (selectedIdx === topIdx) {
        // Wybrana opcja to odpowiednie auto
        const txt = this.intl.t('transport.optimalVehicleSelected');
        return Ember.String.htmlSafe('<span style="color:green;">' + txt + '</span>');
      }

      if (selectedIdx > topIdx) {
        // Możliwe mniejsze auto
        const txt = this.intl.t('transport.smallerVehiclePossible');
        return Ember.String.htmlSafe('<span style="color:blue;">' + txt + topName + ' </span>');
      } // Potrzeba większego auta


      const txt = this.intl.t('transport.biggerVehicleRequired');
      return Ember.String.htmlSafe('<span style="color:darkred;">' + txt + topName + ' </span>');
    }),
    maxSegmentLdm: Ember.computed('model.maxSegmentLdm', function () {
      return this.rounding.roundToTwoDecimals(this.get('model.maxSegmentLdm'));
    }),
    maxSegmentWeight: Ember.computed('model.maxSegmentWeight', function () {
      return this.rounding.roundToTwoDecimals(this.get('model.maxSegmentWeight'));
    })
  });

  _exports.default = _default;
});