define("apollo/pods/components/transport/driver-section/component", ["exports", "jquery", "apollo/mixins/form-utils-mixin", "apollo/mixins/advice-utils-mixin"], function (_exports, _jquery, _formUtilsMixin, _adviceUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUtilsMixin.default, _adviceUtilsMixin.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    relationsTrackerService: Ember.inject.service('transport-relations.relations-tracker'),
    init: async function () {
      this._super();

      if (this.get('advice.content')) {
        this.get('advice').set('rodoAcceptanceChangedManually', false);
      }

      if (this.get('requiresModeOfTransportationVerification')) {
        this.send('setModesOfTransportation');
      }

      if (this.get('advice.content')) {
        this.get('advice').set('didDriverLanguageChanged', false);
      } // Jeśli typ transportu pozawala na tworzenie awizacji okresowych, to musimy pobrać całą mapę firmy przewoźnika
      // która zawiera informację odnośnie możliwości tworzenia awizacji okresowych w danej firmie.


      if (this.get('transport').get('transportType.allowsPeriodicTransport')) {
        await this.store.findRecord('company', this.get('transport.carrierCompany.id'));
      }
    },
    advice: Ember.computed('transport.advice', function () {
      return this.get('transport.advice');
    }),
    countries: Ember.computed('principalCompany', function () {
      return this.get('store').peekRecord('company', localStorage.getItem('principalId')).get('driverLanguages').sortBy('ibanSymbol');
    }),
    nameTermsOfConditionsAcceptedSetting: Ember.computed(function () {
      const principalCompanyId = localStorage.getItem('principalId');
      const termsLabelLanguage = this.get('languageService').findProperLanguageVersion('NAME_TERMS_OF_CONDITIONS_ACCEPTED', 'NAME_TERMS_OF_CONDITIONS_ACCEPTED_EN');
      return this.get('store').peekAll('setting').filterBy('company.id', principalCompanyId).filterBy('name', termsLabelLanguage).get('firstObject');
    }),
    fileTermsOfConditionsAcceptedSetting: Ember.computed(function () {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekAll('setting').filterBy('company.id', principalCompanyId).filterBy('name', 'FILE_TERMS_OF_CONDITIONS_ACCEPTED').get('firstObject');
    }),
    // eslint-disable-next-line ember/no-on-calls-in-components,ember/no-observers
    setRodoAcceptance: Ember.on('init', Ember.observer('advice.id', 'advice.isDirty', 'advice.hasDirtyRelations', function () {
      // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
      Ember.run.once(this, function () {
        this.setRodoValue(this.get('advice'));
      });
    })),
    showPasteAdviceArea: Ember.computed('transport.advice.id', function () {
      return this.get('transport.transportType.pastingFromExcelEnabled');
    }),
    showTrailer: Ember.computed('', function () {
      return this.get('transport.transportType.allowsTrailer');
    }),
    periodicTransportAllowed: Ember.computed('transport.{id,transportType.allowsPeriodicTransport,carrierCompany.allowsPeriodicTransport,contractors.@each.allowsPeriodicTransport}', function () {
      const transport = this.get('transport');

      if (transport.get('id') && transport.get('periodicTransportInternalIndex')) {
        return true;
      }

      if (!transport.get('transportType.allowsPeriodicTransport')) {
        return false;
      }

      return transport.get('carrierCompany').get('allowsPeriodicTransport') || transport.get('contractors').some(c => c.get('allowsPeriodicTransport'));
    }),
    companyIds: Ember.computed('advice.transport.{contractors.[],.carrierCompany.id}', 'transport.carrierCompany', function () {
      const ids = [];

      if (!this.get('sessionAccount.currentUser.isPrincipal')) {
        ids.push(this.get('sessionAccount.currentUser.company.id'));
        return ids;
      }

      this.get('advice.transport.contractors').forEach(c => ids.push(c.get('id')));
      ids.push(this.get('advice.transport.carrierCompany.id'));
      return ids;
    }),
    type: Ember.computed('advice.transport.{contractors.[],.carrierCompany.id}', 'transport.carrierCompany', function () {
      const transportTypeName = this.get('transport').get('transportType.name');
      const relatedTransports = this.get('transport').get('relatedTo');
      const combiningTransportTypeName = this.get('store').peekAll('transportType').find(tt => tt.get('participatesInCombiningTransports') === true);

      if (relatedTransports.length > 0 && !!combiningTransportTypeName) {
        return combiningTransportTypeName.get('name');
      }

      return transportTypeName;
    }),
    requiresModeOfTransportationVerification: Ember.computed('transport.transportType.requiresModeOfTransportationVerification', function () {
      return this.get('transport.transportType.requiresModeOfTransportationVerification');
    }),

    /**
     * modesOfTransportation ustawiane są na podstawie danych z serwerach podczas inicjalizacji tego
     * komponentu (setModeOfTransportation), ale musimy też aktualizować najpóźniejszą godzinę awizacji (tmpTransportDate)
     * podczas edycji z poziomu formularza -- do tego celu potrzebny nam jest ten `observer`.
     */
    // eslint-disable-next-line ember/no-observers
    setTransportDate: Ember.observer('transport.maxTransportDate', function () {
      const maxTransportDate = this.get('transport.maxTransportDate');

      if (this.get('modesOfTransportation')) {
        this.get('modesOfTransportation').forEach(truck => {
          truck.set('tmpTransportDate', maxTransportDate);
        });
      }
    }),
    allowEnterValueToAutoComplete: Ember.computed('transport.transportType', function () {
      return !this.get('transport.transportType.useResourcesFromExternalSource');
    }),
    setDriverDataOnce: function (item, language) {
      this.set('transport.advice.driverName', item.driverName);
      this.set('transport.advice.driverId', item.driverId);
      this.set('transport.advice.driverMobilePhonePrefix', item.driverMobilePhonePrefix);
      this.set('transport.advice.driverMobilePhoneNumber', item.driverMobilePhoneNumber);

      if (!this.get('requiresModeOfTransportationVerification')) {
        this.set('transport.advice.carRegistrationNumber', item.carRegistrationNumber);
        this.set('transport.advice.trailerRegistrationNumber', item.trailerRegistrationNumber);
      }

      this.set('transport.advice.documentNumber', item.documentNumber);
      this.set('transport.advice.driverLanguage', language);
      this.set('transport.advice.termsOfConditionsAccepted', item.termsOfConditionsAccepted);
    },
    setExternalDriverDataOnce: function (item, language) {
      const driverName = this.get('transport.advice.driverName');

      if (!driverName || driverName === item.driverName) {
        this.set('transport.advice.driverName', item.driverName);
        this.set('transport.advice.marcosDriverId', item.marcosDriverId);
      }

      const prefix = this.get('transport.advice.driverMobilePhonePrefix');

      if (!prefix || prefix === item.driverMobilePhonePrefix) {
        this.set('transport.advice.driverMobilePhonePrefix', item.driverMobilePhonePrefix);
      }

      if (!prefix) {
        this.set('transport.advice.driverMobilePhonePrefix', '+48');
      }

      const driverMobilePhoneNumber = this.get('transport.advice.driverMobilePhoneNumber');

      if (!driverMobilePhoneNumber || driverMobilePhoneNumber === item.driverMobilePhoneNumber) {
        this.set('transport.advice.driverMobilePhoneNumber', item.driverMobilePhoneNumber);
      }

      const carRegistrationNumber = this.get('transport.advice.carRegistrationNumber');

      if (!carRegistrationNumber || carRegistrationNumber === item.carRegistrationNumber) {
        this.set('transport.advice.carRegistrationNumber', item.carRegistrationNumber);
        this.set('transport.advice.marcosTruckId', item.marcosTruckId);
      }

      const trailerRegistrationNumber = this.get('transport.advice.trailerRegistrationNumber');

      if (!trailerRegistrationNumber || trailerRegistrationNumber === item.trailerRegistrationNumber) {
        this.set('transport.advice.trailerRegistrationNumber', item.trailerRegistrationNumber);
        this.set('transport.advice.marcosTrailerId', item.marcosTrailerId);
      }

      const documentNumber = this.get('transport.advice.documentNumber');

      if (!documentNumber || documentNumber === item.documentNumber) {
        this.set('transport.advice.documentNumber', item.documentNumber);
      }

      this.set('transport.advice.driverLanguage', language);
      this.set('transport.advice.termsOfConditionsAccepted', item.termsOfConditionsAccepted);
    },
    actions: {
      toggleRodoAcceptance() {
        this.get('advice').set('rodoAcceptanceChangedManually', true);
        this.get('advice').toggleProperty('rodoAccepted');
      },

      setPhonePrefix(phonePrefix) {
        this.set('advice.driverMobilePhonePrefix', phonePrefix);
      },

      setDriverData(item) {
        const self = this;
        const language = item.driverLanguage ? this.get('store').peekRecord('country', item.driverLanguage) : null; // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions

        if (this.get('transport.transportType.useResourcesFromExternalSource')) {
          Ember.run.once(this, 'setExternalDriverDataOnce', item, language);
        } else {
          Ember.run.once(this, 'setDriverDataOnce', item, language);
        }

        if (item.customFields) {
          const transportFields = self.get('transport.customFields');
          item.customFields.forEach(f => {
            const filteredTransportFields = transportFields.filter(field => {
              return field.get('definition.id') === f.definition.toString();
            });

            if (!filteredTransportFields || !filteredTransportFields.length) {
              return true;
            }

            const transportField = filteredTransportFields.get('firstObject');

            if (f.value) {
              transportField.set('value', f.value);
            } else if (f.option) {
              const option = transportField.get('definition.options').filter(o => {
                return o.id === f.option.toString();
              })[0];
              transportField.set('option', option);
            }
          });
        }

        (0, _jquery.default)('.driver-section').find('input').filter(function () {
          return !!(0, _jquery.default)(this).val();
        }).closest('.has-error').removeClass('has-error');
      },

      setDriverName(name) {
        this.get('transport').get('advice').set('driverName', name);
      },

      setDriverLanguage(language, object) {
        const advice = this.get('transport').get('advice');

        if (advice.get('driverLanguage.id') === language.get('id')) {
          return;
        }

        advice.set('driverLanguage', language);
        advice.set('didDriverLanguageChanged', true);
        this.clearBootstrapError((0, _jquery.default)('#' + object.uniqueId));
      },

      handlePastedContent: function (pastedContent) {
        if (!pastedContent || !pastedContent.trim()) {
          return;
        }

        const textMatrix = pastedContent.split(/\r?\n/).map(t => t.split('\t'));
        this.set('transport.advice.rodoAccepted', false);
        this.set('transport.advice.driverName', textMatrix[0][0]);
        this.set('transport.advice.driverMobilePhonePrefix', textMatrix[1][0].replace(/\s+/g, ''));
        this.set('transport.advice.driverMobilePhoneNumber', textMatrix[1][1].replace(/\s+/g, ''));
        this.set('transport.advice.documentNumber', textMatrix[2][0]);
        this.set('transport.advice.carRegistrationNumber', textMatrix[3][0].replace(/\s+/g, ''));
        this.set('transport.advice.trailerRegistrationNumber', textMatrix[4][0].replace(/\s+/g, ''));
        const ibanSymbol = textMatrix[5][0] === 'EN' ? 'GB' : textMatrix[5][0];
        const language = this.get('store').peekAll('country').filterBy('ibanSymbol', ibanSymbol).get('firstObject');
        this.set('transport.advice.driverLanguage', language);
        const transport = this.get('store').peekRecord('transport', this.get('transport.id'));
        transport.validate();
      },
      setModesOfTransportation: function () {
        const self = this;
        const maxTransportDate = this.get('transport.maxTransportDate');
        self.get('store').query('truck', {
          isModeOfTransportation: true,
          enabled: true,
          companyId: self.get('transport.carrierCompany.id'),
          principalCompanyId: localStorage.getItem('principalId'),
          max: 999 // TODO: "Paginacja" na polu Środek transportu w forularzu awizacji

        }).then(trucks => {
          const filteredTrucks = trucks.filter(truck => {
            return ![truck.STATUS.AWAITING_VERIFICATION, truck.STATUS.DISABLED].includes(truck.get('status'));
          });
          filteredTrucks.forEach(truck => {
            truck.set('tmpTransportDate', maxTransportDate);
          });
          self.set('modesOfTransportation', filteredTrucks);
        });
      },
      setModeOfTransportation: function (modeOfTransportation) {
        const self = this;
        self.set('advice.carRegistrationNumber', modeOfTransportation.get('registrationNumber'));
        self.set('advice.truck', modeOfTransportation);
      }
    }
  });

  _exports.default = _default;
});