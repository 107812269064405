define("apollo/pods/planning/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);
    },

    currentDeliveries: Ember.A([]),
    currentTransports: Ember.A([]),
    deliveries: Ember.computed('currentDeliveries', 'model.deliveries.{length,[]}', function () {
      return this._refreshArray(this.get('model.deliveries'), this.currentDeliveries);
    }),
    transports: Ember.computed('currentTransports', 'model.transports.{length,[]}', function () {
      return this._refreshArray(this.get('model.transports'), this.currentTransports).sort(t => t.internalIndex);
    }),
    _refreshArray: function (modelArray, currentArray) {
      if (!modelArray || modelArray.length === 0 && !modelArray.content) {
        return currentArray;
      }

      modelArray.forEach(t => {
        if (!currentArray.includes(t)) {
          currentArray.pushObject(t);
        }
      });
      currentArray.forEach(t => {
        if (!modelArray.includes(t)) {
          currentArray.removeObject(t);
        }
      });
      return currentArray;
    },
    customFieldsForDeliveries: Ember.computed('model.customFields.@each.{id,enabled,visibleInDelivery}', function () {
      return this.get('model.customFields').filterBy('id').filterBy('enabled').filterBy('visibleInDelivery').sortBy('idx');
    }),
    customFieldsForTransport: Ember.computed('model.customFields.@each.{id,enabled,obligatory,visibleDuringTransportCreation}', function () {
      return this.get('model.customFields').filterBy('id').filterBy('enabled').filterBy('visibleDuringTransportCreation').filterBy('obligatory').sortBy('idx');
    })
  });

  _exports.default = _default;
});