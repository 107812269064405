define("apollo/pods/offer/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend({
    // TODO :: refactor ?
    serializeIntoHash: function (data, type, snapshot, options) {
      const object = this.serialize(snapshot, options);

      for (const key in object) {
        data[key] = object[key];
      }

      data.id = snapshot.record.id;
      data.isAcceptedWithMinPrice = snapshot.record.get('isAcceptedWithMinPrice');
    }
  });

  _exports.default = _default;
});