define("apollo/pods/components/files-server-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    filteredFiles: undefined,
    myPageSizes: Ember.computed(function () {
      return [{
        label: 5,
        value: 5
      }, {
        label: 10,
        value: 10
      }, {
        label: 20,
        value: 20
      }, {
        label: 30,
        value: 30
      }, {
        label: 40,
        value: 40
      }, {
        label: 50,
        value: 50
      }];
    }),
    columns: Ember.computed(function () {
      return [{
        propertyName: 'transport',
        title: this.get('intl').t('files.table.column.transport')
      }, {
        propertyName: 'name',
        title: this.get('intl').t('files.table.column.name')
      }, {
        propertyName: 'date',
        title: this.get('intl').t('files.table.column.fileDate'),
        className: 'column-date',
        filteredBy: 'dateRange',
        componentForFilterCell: 'server-table/filters/date-range-filter'
      }, {
        propertyName: 'transport-load-date',
        title: this.get('intl').t('files.table.column.transportLoadDate'),
        className: 'column-date',
        filteredBy: 'transportLoadDateRange',
        componentForFilterCell: 'server-table/filters/date-range-filter'
      }, {
        propertyName: 'warehouse',
        title: this.get('intl').t('files.table.column.warehouse')
      }];
    }),
    actions: {
      setFiles(files) {
        this.set('filteredFiles', files);
      },

      saveFileSet: function () {
        this.saveFiles(this.get('filteredFiles'));
      }
    }
  });

  _exports.default = _default;
});