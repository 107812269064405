define("apollo/pods/components/modals/remove-transport-relation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedRelation: null,
    sessionAccount: Ember.inject.service('session-account'),
    tagName: '',
    relations: Ember.computed.reads('relatedTo'),
    actions: {
      onDeleteHandler: function () {
        if (!this.get('selectedRelation')) {
          return;
        }

        this.onDelete(this.get('selectedRelation'));
        this.send('onCloseHandler');
      },
      onCloseHandler: function () {
        this.set('selectedRelation', null);
        this.onClose();
      }
    }
  });

  _exports.default = _default;
});