define("apollo/pods/components/auction/auction-details-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isCarrier: Ember.computed('model.isCarrier', function () {
      return this.get('model.isCarrier');
    }),
    showBidCounter: Ember.computed('transport.currentBidCount', function () {
      return !this.get('isCarrier') && !isNaN(this.get('currentBidCount'));
    }),
    currentBidCount: Ember.computed('transport.currentBidCount', function () {
      return Number(this.get('transport.currentBidCount'));
    }),
    actions: {
      async showAuctionDetailsForm() {
        const transport = this.get('transport');
        const isCarrier = this.get('isCarrier');
        this.set('action', 'showModal');
        const model = {
          transport,
          isCarrier
        };
        this.sendAction('action', 'auctions.modals.auction-details-form', model);
      }

    }
  });

  _exports.default = _default;
});