define("apollo/pods/components/field-error-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inject: {
      service
    }
  } = Ember;

  var _default = Ember.Component.extend({
    intl: service(),
    tagName: 'span',
    attributeBindings: ['customFieldName:data-field-name'],
    customFieldName: 'field-error-span',
    generalErrors: ['blank', 'blankSelect', 'blankCheckbox', 'tooShort', 'tooLong', 'positiveNumber', 'positiveInteger', 'timeHour', 'noNegativeNumber', 'number', 'invalidDatesSequence', 'invalidQuantity', 'orderInTransport'],
    messageKey: Ember.computed('modelName', 'error.attribute', 'error.message', function () {
      if (this.get('generalErrors').some(r => this.get('error.message').indexOf(r) >= 0)) {
        return `errors.${this.get('error.message')}`;
      }

      return `${this.get('modelName')}.errors.${this.get('error.attribute')}.${this.get('error.message')}`;
    }),
    errorMessage: Ember.computed('messageKey', 'error.message', function () {
      const key = this.get('messageKey');

      if (this.get('intl').exists(key)) {
        return this.get('intl').t(key);
      } else {
        return this.get('error.message');
      }
    })
  });

  _exports.default = _default;
});