define("apollo/services/session-account", ["exports", "apollo/mixins/persistence-mixin"], function (_exports, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_persistenceMixin.default, {
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    errors: Ember.A([]),
    intl: Ember.inject.service(),
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    authoritiesDependentOnPricingPlan: {},
    _setSepcificTranslations: async function (translations) {
      let language = this.get('language');

      if (!language) {
        language = this.intl.locale[0].substring(0, 2);
      }

      if (!translations) {
        return;
      }

      this.intl.addTranslations(`${language}`, translations);
    },

    loadCurrentUser() {
      const self = this;
      const username = this.get('session.data.authenticated.username');
      const token = this.get('session.data.authenticated.token');
      this.set('token', token);

      if (Ember.isEmpty(username)) {
        return;
      }

      return new Ember.RSVP.Promise(async function (resolve, reject) {
        try {
          const request = await self.apolloApiService.callApolloApi(self.apolloApiService.APOLLO_API.PERSON.ACCOUNT_MAP, {
            username,
            lang: self.get('language')
          });

          if (request.ok) {
            const response = await request.json();

            if (Ember.isEmpty(response.user)) {
              reject(new Error('No user provided in response.'));
            }

            await self._setSepcificTranslations(response.translations);
            Ember.run.next(function () {
              if (response.meta) {
                self.get('session').set('backendVersion', response.meta.appVersion);
              }

              if (!self.get('store').hasRecordForId('user', response.user.id)) {
                self.get('store').pushPayload(response);
              }

              const user = self.get('store').peekRecord('user', response.user.id);
              user.set('availableLanguages', response.availableLanguages);
              self.set('notificationTypes', response.notificationTypes);
              self.set('authoritiesDependentOnPricingPlan', response.authoritiesDependentOnPricingPlan);
              self.set('actionTypes', response.actionTypes);
              self.set('pricingPlans', response.pricingPlans);
              self.set('principalCompanyId', response.user.principalCompanyId);
              self.set('resetPasswordIsDisabled', response.user.resetPasswordIsDisabled);
              self.set('statuteAccepted', user.get('statuteAccepted'));
              self.set('currentUser', user);
              self.set('authorityRestrictions', self.get('store').peekAll('authority-restriction').filter(ar => {
                return ar.get('roleGroup.id') === user.get('roleGroup.id') || ar.get('pricingPlan') === user.get('company.pricingPlan');
              }));
              self.get('store').peekAll('transportType').forEach(tt => {
                const authorityRestrictions = self.getAllPositiveAuthorityRestrictionsForGivenTransportType(tt);
                tt.set('authorityRestrictions', authorityRestrictions);
              }); // Poniższy get służy załadowaniu ramp do sesji ember-data.
              // Bez niego zdarzało się, że rampy dublowały się po wczytaniu szczegółów
              // innego użytkownika (por. A-329, A-392).

              user.get('ramps');
              self.removeDuplicateRecords(user.get('roleGroup'), 'notifications');
              self.removeDuplicateRecords(user, 'ramps');
              const columnNames = user.get('tableColumns').map(c => {
                return c.get('name');
              });
              self.set('columnNames', columnNames);
              localStorage.removeItem('principalAlias');
              const principalCompany = self.get('store').peekRecord('company', self.get('principalCompanyId'));

              if (principalCompany) {
                localStorage.setItem('principalAlias', principalCompany.get('alias'));
              }

              self.set('principalsWhereUserHasRole', self.get('store').peekAll('company').filter(company => response.principalsWhereUserHasRole.includes(Number(company.get('id')))));
              localStorage.setItem('principalId', self.get('principalCompanyId'));
              const partnershipType = self.get('store').peekAll('partnershipType').find(pt => Number(pt.get('id')) === response.company.partnershipType);
              self.set('currentUser.company.partnershipType', partnershipType);
              resolve(user);
            });
          } else {
            self.get('session').invalidate();
          }
        } catch (e) {
          console.error(e);
          self.get('session').invalidate();
        }
      });
    },

    isHTTPS: function () {
      return location.protocol === 'https:';
    },
    currentCompany: Ember.computed('currentUser.company', function () {
      return this.get('currentUser.company');
    }),
    principalCompany: Ember.computed('principalCompanyId', function () {
      return this.get('store').peekRecord('company', this.get('principalCompanyId'));
    }),
    isPrincipal: Ember.computed('currentUser.isPrincipal', function () {
      return this.get('currentUser').get('isPrincipal');
    }),
    hasRoleTransportTable: Ember.computed('currentUser.roles', function () {
      return this.hasRole('ROLE_TRANSPORT_TABLE');
    }),
    isSuperUser: Ember.computed('currentUser.roleGroup.name', function () {
      return this.get('currentUser.roleGroup.name') === 'SUPER_USER';
    }),

    hasAnyOfTheRoles(roles) {
      const self = this;
      let hasAnyOfTheRoles = false;
      roles.forEach(function (role) {
        if (self.hasRole(role)) {
          hasAnyOfTheRoles = true;
          return false;
        }
      });
      return hasAnyOfTheRoles;
    },

    hasRole(name) {
      if (!this.get('session').get('isAuthenticated')) {
        return false;
      }

      return this.get('currentUser').get('roleNames').includes(name);
    },

    getSettingValue(name) {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekAll('setting').filterBy('company.id', principalCompanyId).filterBy('name', name).get('firstObject.value');
    },

    getParameterValue(name) {
      return this.get('store').peekAll('parameter').filterBy('name', name).get('firstObject.value');
    },

    getTransactionSetting(transportType) {
      return this.get('store').peekAll('transaction-setting').filterBy('transportType.id', transportType.get('id')).get('firstObject');
    },

    getAuctionSetting(transportTypeId) {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekAll('auction-setting').filterBy('company.id', principalCompanyId).filterBy('transportType.id', transportTypeId).get('firstObject');
    },

    getAppliesSetting() {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekAll('applies-setting').filterBy('principalCompany.id', principalCompanyId).get('firstObject');
    },

    getProductSetting() {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekAll('product-setting').filterBy('company.id', principalCompanyId).get('firstObject');
    },

    getIndexSetting(transportTypeId) {
      if (!transportTypeId) {
        return;
      }

      return this.get('store').peekAll('index-setting').filterBy('transportType.id', transportTypeId).get('firstObject');
    },

    getCourierSetting(transportTypeId) {
      if (!transportTypeId) {
        return;
      }

      return this.get('store').peekAll('courier-setting').filterBy('transportType.id', transportTypeId).get('firstObject');
    },

    getSquareSetting() {
      const principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekAll('square-setting').find(ss => ss.get('company.id') === principalCompanyId);
    },

    getPackageTypeTransportTypeForTransportType(transportTypeId) {
      return this.get('store').peekAll('packageTypeTransportType').filter(pttt => pttt.get('transportType.id') === transportTypeId);
    },

    getPackageTypeTransportType(packageTypeId, transportTypeId) {
      return this.get('store').peekAll('packageTypeTransportType').find(pttt => pttt.get('transportType.id') === transportTypeId && pttt.get('packageType.id') === packageTypeId);
    },

    isGroupFilterEnabled() {
      const setting = this.get('sessionAccount').getSettingValue('ROLES_WITH_ENABLED_USER_AND_GROUP_FILTER');
      return setting ? setting.search(this.get('currentUser.roleGroup.name')) > -1 : false;
    },

    isGroupFilterEnabledForUser(user) {
      const setting = this.get('sessionAccount').getSettingValue('ROLES_WITH_ENABLED_USER_AND_GROUP_FILTER');
      return setting ? setting.search(user.get('roleGroup.name')) > -1 : false;
    },

    getAllPositiveAuthorityRestrictionsForGivenTransportType: function (transportType) {
      const assetIds = transportType.get('connectedAssetIds').map(id => Number(id));
      return this.get('authorityRestrictions').filter(ar => assetIds.includes(ar.get('assetId')) && ar.get('authorityRange') !== 'BLOCKED' && (!ar.get('pricingPlan') || ar.get('pricingPlan') === this.get('currentUser.company.pricingPlan')));
    },

    // TYLKO POWIĄZANE Z TYPEM TRANSPORTU
    getPositiveAuthorityRestrictionsByTransportTypeId(transportTypeId) {
      return this.get('authorityRestrictions').filter(ar => ar.get('assetId') === Number(transportTypeId) && ar.get('authorityRange') !== 'BLOCKED' && (!ar.get('pricingPlan') || ar.get('pricingPlan') === this.get('currentUser.company.pricingPlan')));
    },

    canAccessAnyTransportType: Ember.computed('currentUser.roles', function () {
      return this.hasAuthorityAtAll('ACCESS_TRANSPORT');
    }),
    hasAuthorityAtAll: function (actionName) {
      const authorityRestrictions = this.get('authorityRestrictions');
      return authorityRestrictions.some(ar => {
        const authority = this.get('currentUser.roles').find(a => a && a.get('id') === ar.get('authority.id'));
        return authority.get('name') === actionName && ar.get('authorityRange') !== 'BLOCKED' && (!ar.get('pricingPlan') || ar.get('pricingPlan') === this.get('currentUser.company.pricingPlan'));
      });
    },
    hasAuthorityInTransportType: function (actionName, transportTypeId) {
      return this.getPositiveAuthorityRestrictionsByTransportTypeId(Number(transportTypeId)).find(ar => ar.get('name') === actionName);
    },
    hasAuthorityInTransportTypeByAlias: function (actionName, transportTypeAlias) {
      const transportType = this.get('store').peekAll('transportType').filterBy('alias', transportTypeAlias)[0];

      if (!transportType) {
        console.debug('Nie znaleziono typu transportu :: ' + transportTypeAlias);
        return false;
      }

      return this.hasAuthorityInTransportType(actionName, transportType.get('id'));
    }
  });

  _exports.default = _default;
});