define("apollo/pods/offer/adapter", ["exports", "apollo/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForUpdateRecord(id, modelName, snapshot) {
      if (!snapshot.record.enabled && snapshot.record.deleted) {
        return `${this.urlPrefix()}/offers/revoke-offer/${id}`;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});