define("apollo/pods/components/show-delivery-invoice-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1ys05TCB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n    \"],[4,\"if\",[[23,[\"invoiceNumber\"]]],null,{\"statements\":[[7,\"div\"],[9],[1,[27,\"t\",[\"invoice.number\"],null],false],[0,\": \"],[1,[21,\"invoiceNumber\"],false],[10]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"if\",[[23,[\"price\"]]],null,{\"statements\":[[7,\"div\"],[9],[1,[27,\"t\",[\"invoice.price\"],null],false],[0,\": \"],[1,[21,\"price\"],false],[10]],\"parameters\":[]},null],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/show-delivery-invoice-details/template.hbs"
    }
  });

  _exports.default = _default;
});