define("apollo/pods/offer/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;
  const OFFER_TRANSIT_TIME_TYPE = Object.freeze({
    DAYS: 'DAYS',
    HOURS: 'HOURS'
  });

  var _default = _model.default.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    auction: belongsTo('auction'),
    company: belongsTo('company'),
    creator: belongsTo('user'),
    operator: belongsTo('operator'),
    loadingPort: belongsTo('port'),
    unloadingPort: belongsTo('port'),
    price: attr('number'),
    enabled: attr('boolean'),
    valid: attr('boolean'),
    hasWon: attr('boolean'),
    deleted: attr('boolean'),
    place: attr('number'),
    description: attr('string'),
    transitTime: attr('number'),
    transitTimeType: attr('string'),
    isPreferred: attr('boolean'),
    dateCreated: attr('isodate'),
    descriptionTrimValue: 23,
    validations: {
      unloadingPort: {
        custom: [{
          validation: function (key, value, model) {
            const auctionSetting = model.get('auctionSetting');

            if (!auctionSetting || !auctionSetting.get('requirePorts')) {
              return true;
            }

            return value.get('id');
          },
          message: 'blank'
        }]
      },
      loadingPort: {
        custom: [{
          validation: function (key, value, model) {
            const auctionSetting = model.get('auctionSetting');

            if (!auctionSetting || !auctionSetting.get('requirePorts')) {
              return true;
            }

            return value.get('id');
          },
          message: 'blank'
        }]
      },
      operator: {
        custom: [{
          validation: function (key, value, model) {
            const auctionSetting = model.get('auctionSetting');

            if (!auctionSetting || !auctionSetting.get('requireOperator')) {
              return true;
            }

            return value.get('id');
          },
          message: 'blank'
        }]
      },
      price: {
        custom: [{
          validation: function (key, value) {
            return value && parseFloat(value) >= 1;
          },
          message: 'nonPositiveNumber'
        }, {
          validation: function (key, value, model) {
            if (model.get('isAcceptedWithMinPrice')) {
              return true;
            }

            if (!value || value < 1) {
              return true;
            }

            const auctionSetting = model.get('auctionSetting');

            if (!auctionSetting || !auctionSetting.get('requireMinOfferReductionSizeValidation')) {
              return true;
            }

            const suggestedPrice = model.get('auction.suggestedPriceForLoggedCompany');

            if (!suggestedPrice) {
              return true;
            }

            return parseFloat(value) <= suggestedPrice;
          },
          message: function (key, value, self) {
            const principalCompanyName = self.get('auction.transport.principalCompany.shortName');
            const minReductionSize = self.get('auction.minReductionSize');
            return self.get('intl').t('offer.errors.price.reductionNotMet', {
              principalCompanyName,
              minReductionSize,
              currencySymbol: self.get('currency.symbol')
            }).toString();
          }
        }, {
          validation: function (key, value, model) {
            const auctionSetting = model.get('auctionSetting');

            if (!auctionSetting) {
              return true;
            }

            if (!auctionSetting.get('canAcceptWithMinPrice')) {
              return model.get('auction.minPrice') <= parseFloat(value);
            }

            return true;
          },
          message: 'lowerThanMinimum'
        }]
      },
      transitTime: {
        custom: [{
          validation: function (key, value, model) {
            const auctionSetting = model.get('auctionSetting');

            if (model.get('transitTimeType') && !value) {
              return false;
            }

            if (auctionSetting.get('requireTransitTime')) {
              return !!value;
            }

            return true;
          },
          message: 'blank'
        }]
      },
      transitTimeType: {
        custom: [{
          validation: function (key, value, model) {
            const auctionSetting = model.get('auctionSetting');

            if (model.get('transitTime') && !value) {
              return false;
            }

            if (auctionSetting.get('requireTransitTime')) {
              return !!value;
            }

            return true;
          },
          message: 'blankSelect'
        }]
      },
      description: {
        custom: [{
          validation: function (key, value, model) {
            const auctionSetting = model.get('auctionSetting');

            if (auctionSetting.get('requireDescriptionSection')) {
              return !!value;
            }

            return true;
          },
          message: 'blank'
        }]
      }
    },
    auctionSetting: Ember.computed('auction.transport.transportType.id', function () {
      return this.get('sessionAccount').getAuctionSetting(this.get('auction.transport.transportType.id'));
    }),
    currency: Ember.computed('auction.currency', function () {
      return this.get('auction.currency');
    }),
    formattedPrice: Ember.computed('price', 'currency.symbol', function () {
      if (!this.get('price')) {
        return '';
      }

      return `${this.get('price')} ${this.get('currency.symbol')}`;
    }),
    transitTimeTypes: Ember.computed(function () {
      return Object.values(OFFER_TRANSIT_TIME_TYPE);
    }),
    displayPassToCarrierButton: Ember.computed('auction.transport.auctions.@each.offers.length', function () {
      const self = this;

      if (!this.get('enabled')) {
        return false;
      }

      const transport = this.get('store').peekRecord('transport', this.get('auction').get('transport.id'));
      let showButton = true;
      const sortedAuctions = transport.get('auctions').sortBy('dateCreated').reverse();

      for (let i = 0; i < sortedAuctions.length; i++) {
        if (sortedAuctions[i].get('id') === self.get('auction.id')) {
          break;
        }

        const offers = sortedAuctions[i].get('offers').sortBy('company');

        for (let j = 0; j < offers.length; j++) {
          if (offers[j].get('company.id') === self.get('company.id') && offers[j].get('id') !== self.get('id')) {
            showButton = false;
            break;
          }
        }
      }

      return showButton;
    }),
    isAcceptedWithMinPrice: Ember.computed('', {
      get(key) {
        return this.get('model.isAcceptedWithMinPrice') ? this.get('model.isAcceptedWithMinPrice') : false;
      },

      set(key, value) {
        return value;
      }

    }),
    setSoftDeleted: function () {
      this.set('enabled', false);
      this.set('deleted', true);
      this.set('invalid', true);
      return this;
    },
    unsetSoftDeleted: function () {
      this.set('enabled', true);
      this.set('deleted', false);
      this.set('invalid', false);
      return this;
    }
  });

  _exports.default = _default;
});